import React from 'react'
import './customModal.css'
import RedBtn from '../redBtn/RedBtn'

const CustomModal = ({ setIsPopupOpen, isPopupOpen, popupType }) => {
  const handleClose = () => {
    setIsPopupOpen(false)
  }

  return (
    <>
      {isPopupOpen && (
        <div className='modal-wrapper'>
          <div className={`content_wrapper ${popupType}`}>
            {popupType === 'confirm' && (
              <>
                <div className='popup_title'>Успешно!</div>
                <div className='popup_desc'>Ваша заявка была отправлена.</div>
              </>
            )}
            {popupType === 'error' && (
              <>
                <div className='popup_title'>Ошибка!</div>
                <div className='popup_desc'>Что-то пошло не так. Пожалуйста, повторите попытку позже.</div>
              </>
            )}
            <div className='btn'>
              <RedBtn btnLabel='Ok' arrow onClick={handleClose} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomModal
