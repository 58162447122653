import React from 'react'
import s from './CryptoOperations.module.css'
import HeroWithBanner from '../../components/hero-with-banner/HeroWithBanner'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import hero_banner from '../../assets/crypto-operations/hero-banner.jpeg'
import payment_services from '../../assets/crypto-operations/payment-services.jpeg'
import { t } from 'i18next'

function CryptoOperations() {
  const heroData = {
    heroImage: hero_banner,
    title: t('crypto_operations.title'),
    desc: t('crypto_operations.hero'),
    btnLabel: t('buttons.open_account'),
    btnLink: '/register',
    bgGradient: true,
    textLeft: true,
  }

  return (
    <div className={s.crypto_currency_page}>
      {/* hero section */}
      <HeroWithBanner {...heroData} />

      {/* crypto payment gateway */}
      <div className={`${s.crypto_payment} container`}>
        <h2>{t('crypto_operations.gateway')}</h2>
        <div className={s.btn}>
          <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
        </div>
      </div>

      {/* payment services */}
      <div className={`${s.payment_services} container`}>
        <h3 className={s.title}>{t('crypto_operations.desc')}</h3>
        <div className={s.flex}>
          <img src={payment_services} className={s.left} alt='crypto currency' />
          <div className={s.right}>
            <div className={s.line}>
              <div className={s.gradient_card}>
                <p>01</p>
                <p>{t('crypto_operations.card1')}</p>
              </div>
              <div className={s.white_card}>
                <p>02</p>
                <p>{t('crypto_operations.card2')}</p>
              </div>
            </div>
            <div className={s.line}>
              <div className={s.gradient_card}>
                <p>03</p>
                <p>{t('crypto_operations.card3')}</p>
              </div>
              <div className={s.white_card}>
                <p>04</p>
                <p>{t('crypto_operations.card4')}</p>
              </div>
            </div>
            <div className={s.line}>
              <div className={s.gradient_card}>
                <p>05</p>
                <p>{t('crypto_operations.card5')}</p>
              </div>
              <div className={s.white_card}>
                <p>06</p>
                <p>{t('crypto_operations.card6')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CryptoOperations
