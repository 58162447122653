import React, { useState } from 'react'
import SignInFinal from '../SignIn/SignInFinal/SignInFinal'
import RegistrationForm from './RegistrationForm/RegistrationForm'
import { Link } from 'react-router-dom'
import BackIcon from '../../assets/icons/arrow.svg'
import Logo from '../../assets/logo_with_text.svg'

function RegistrationSteps() {
  const [current, setCurrent] = useState(0)
  const [dataForSend, setDataForSend] = useState({
    email: '',
    password: '',
    emailCode: '',
  })

  const steps = [
    {
      content: (
        <RegistrationForm setCurrent={setCurrent} current={current} setLoginData={setDataForSend} dataForSend={dataForSend} />
      ),
    },
    {
      content: (
        <SignInFinal setCurrent={setCurrent} dataForSend={dataForSend} setDataForSend={setDataForSend} current={current} />
      ),
    },
  ]

  const handleBackClick = () => {
    if (current === 1) {
      setCurrent(0)
    }
  }

  return (
    <>
      <div className='auth-wrapper'>
        <div className='auth-container container'>
          <Link to={current === 0 ? '/' : ''} onClick={handleBackClick}>
            <img src={BackIcon} alt='Back' className='back_arrow_icon' />
          </Link>
          <div className='sign_in_content'>
            <img src={Logo} alt='Logo' className='auth-logo-img' />
            <div>{steps[current].content}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationSteps
