import React from 'react'
import './PhysicalClients.css'
import RedBtn from '../../components/ui/redBtn/RedBtn'

import heroImg from '../../assets/physical-clients/hero-img.png'
import profile_icon from '../../assets/physical-clients/profile-icon.svg'
import group_icon from '../../assets/physical-clients/group-icon.svg'
import SplitCard from '../../components/SplitCard/SplitCard'
import { t } from 'i18next'

function PhysicalClients() {
  const PhyDocData = [
    { content: t('physical_clients.line1') },
    { content: t('physical_clients.line2') },
    { content: t('physical_clients.line3') },
    { content: t('physical_clients.line4') },
  ]

  const heroData = {
    img: heroImg,
    title: t('physical_clients.title'),
    description: t('physical_clients.hero'),
    btnLabel: t('buttons.open_account'),
    btnLink: '/register',
    isHero: true,
  }

  return (
    <section className=' section'>
      {/* hero section */}
      <SplitCard {...heroData} />
      <div className='container'>
        <div className='open-account-section'>
          <div>
            <h2 className='corporate-subtitle'>{t('physical_clients.acc_opening')}</h2>
          </div>

          <div class='opening-account'>
            <div class='opening-account-left'>
              <p>{t('physical_clients.desc')}</p>
              <div class='btn-wrapper'>
                <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' white arrow />
              </div>
            </div>

            <div class='opening-account-right'>
              <div class='account-row'>
                <div class='account-row-content'>
                  <div class='icon'>
                    <img src={profile_icon} alt='Feature 1' />
                  </div>
                  <div class='text'>
                    <h3>{t('physical_clients.card_1_title')}</h3>
                    <p>{t('physical_clients.card_2_text')}</p>
                  </div>
                </div>
              </div>

              <div class='account-row'>
                <div class='account-row-content'>
                  <div class='icon'>
                    <img src={group_icon} alt='Feature 2' />
                  </div>
                  <div class='text'>
                    <h3>{t('physical_clients.card_2_title')}</h3>
                    <p>{t('physical_clients.card_2_text')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='open-account-docs'>
          <div>
            <h2 className='corporate-subtitle'>{t('physical_clients.docs')}</h2>
          </div>
          <div className='doc-wraper'>
            <div className='document-title'>
              <h3>{t('physical_clients.t_title')}</h3>
            </div>

            <div className='white-box'>
              {PhyDocData.map((_, index) => {
                return (
                  <div key={index}>
                    <p>{_.content}</p>
                    {index < PhyDocData.length - 1 && <hr />}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PhysicalClients
