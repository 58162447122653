import React, { useState, useRef, useEffect } from 'react'
import arrowUp from '../../../../assets/arrow-up.png'
import arrowDown from '../../../../assets/arrow-down.png'

const CustomDropdown = ({ options, selectedValue, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const selectedItemRef = useRef(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleOptionClick = option => {
    onChange({ target: { value: option.number } })
    setIsOpen(false)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isOpen && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [isOpen])

  const getOptionDisplay = option => `${option.number} - ${option.balance} ${option.currency}`

  return (
    <div className='custom-dropdown' ref={dropdownRef}>
      <div className='custom-dropdown-header' onClick={toggleDropdown}>
        <span>{selectedValue ? getOptionDisplay(options.find(o => o.number === selectedValue)) : placeholder}</span>
        <span className='custom-dropdown-arrow'>
          <img className='dropdown-arrow-icon' src={isOpen ? arrowUp : arrowDown} alt='arrow' />
        </span>
      </div>
      {isOpen && (
        <ul className='custom-dropdown-list'>
          {options.map(option => (
            <li
              key={option.key}
              ref={selectedValue === option.number ? selectedItemRef : null}
              className={`custom-dropdown-list-item ${selectedValue === option.number ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}>
              {getOptionDisplay(option)}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default CustomDropdown
