import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import store, { useAppSelector } from './store/store'

// Layouts
import Layout from './Layout'
import SideMenuLayout from './sideMenuLayout'

// public pages
import Home from './public-pages/home/Home.jsx'
import AboutBank from './public-pages/about-bank/AboutBank.jsx'
import TariffsAndServices from './public-pages/tariffs-and-services/TariffsAndServices.jsx'
import CorporateClients from './public-pages/corporate-clients/CorporateClients.jsx'
import PhysicalClients from './public-pages/physical-clients/PhysicalClients.jsx'
import OtherServices from './public-pages/other-services/OtherServices.jsx'
import CryptoOperations from './public-pages/crypto-operations/CryptoOperations.jsx'
import PaymentCards from './public-pages/payment-cards/PaymentCards.jsx'
import Contact from './public-pages/contact/Contact.jsx'

// Authentication Pages
import SignInSteps from './Authentication/SignIn/SignInSteps'
import RegistrationSteps from './Authentication/Registration/RegistrationSteps'
import ForgotPassword from './Authentication/ForgotPassword/ForgotPassword'

// Private Pages
import Accounts from './components/ui/currencyAccounts/Currency Accounts/currencyAccounts'
import { t } from 'i18next'
import { useEffect } from 'react'
import i18n from './i18n.js'

// Private Route Wrapper
function PrivateRoute({ element }) {
  const userToken = useAppSelector(store => store.userReducer.user.userToken)
  return userToken ? element : <Navigate to='/login' replace />
}

function App() {
  useEffect(() => {
    document.title = t('page_title')
  }, [i18n.language])

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='about-bank' element={<AboutBank />} />
            <Route path='tariffs-and-services' element={<TariffsAndServices />} />
            <Route path='corporate-clients' element={<CorporateClients />} />
            <Route path='physical-clients' element={<PhysicalClients />} />
            <Route path='payment-cards' element={<PaymentCards />} />
            <Route path='crypto-operations' element={<CryptoOperations />} />
            <Route path='other-services' element={<OtherServices />} />
            <Route path='contact' element={<Contact />} />
          </Route>

          {/* Authentication Routes */}
          <Route path='/login' element={<SignInSteps />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />
          <Route path='/register' element={<RegistrationSteps />} />

          {/* Private Routes */}
          <Route path='/kabinet' element={<PrivateRoute element={<SideMenuLayout />} />}>
            <Route path='currencyAccounts' element={<Accounts />} />
          </Route>

          {/* Catch-all route for 404 Not Found */}
          <Route path='*' element={<h1>404 - Page Not Found</h1>} />
        </Routes>
      </Router>
    </Provider>
  )
}

export default App
