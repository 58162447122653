import React from 'react'
import s from './SplitCard.module.css'
import RedBtn from '../../components/ui/redBtn/RedBtn'

// payment methods icons
import visa from '../../assets/other-services/paymentMethods/visa.png'
import mastercard from '../../assets/other-services/paymentMethods/mastercard.png'
import unionPay from '../../assets/other-services/paymentMethods/union-pay.png'
import applePay from '../../assets/other-services/paymentMethods/apple-pay.png'
import discover from '../../assets/other-services/paymentMethods/discover.png'
import jcb from '../../assets/other-services/paymentMethods/JCB.png'
import amex from '../../assets/other-services/paymentMethods/american-express.png'

const paymentMethodsIcons = [
  { src: visa, alt: 'Visa' },
  { src: mastercard, alt: 'MasterCard' },
  { src: unionPay, alt: 'UnionPay' },
  { src: applePay, alt: 'Apple Pay' },
  { src: discover, alt: 'Discover' },
  { src: jcb, alt: 'JCB' },
  { src: amex, alt: 'Amex' },
]
const duplicatedIcons = [...paymentMethodsIcons, ...paymentMethodsIcons]

const SplitCard = ({
  isHero = false,
  title,
  description,
  img,
  btnLabel,
  btnLink,
  bgGradient = false,
  textFirst = isHero ? false : true,
  showPaymentMethods = false,
}) => {
  return (
    <div className={`${s.component} container`}>
      {isHero ? <h1 className={s.title}>{title}</h1> : <h2 className={s.title}>{title}</h2>}
      <div className={`${s.flex} ${textFirst ? '' : s.reverse} ${isHero ? s.hero_padding : ''}`}>
        <div className={`${s.left} ${bgGradient ? s.bg_gradient : s.bg_white}`}>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <div className={`${s.btn} ${isHero ? s.mt : ''}`}>
            <RedBtn btnLabel={btnLabel} btnLink={btnLink} white={bgGradient} arrow />
          </div>
        </div>
        <img src={img} alt={title} className={s.right} />
      </div>
      {showPaymentMethods && (
        <div className={s.scroll_container}>
          <div className={s.scroll_content}>
            {duplicatedIcons.map(icon => (
              <div key={icon.alt} className={s.icon}>
                <img src={icon.src} alt={icon.alt} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SplitCard
