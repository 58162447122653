import React from 'react'
import s from './OtherServices.module.css'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import SplitCard from '../../components/SplitCard/SplitCard'

import hero_img from '../../assets/other-services/hero-img.jpeg'
import dollar_tree from '../../assets/other-services/dollar-tree.png'
import cash_machine from '../../assets/other-services/cash-machine.jpeg'
import international_acquiring from '../../assets/other-services/international-acquiring.png'
import precious_metals_operations from '../../assets/other-services/precious-metals-operations.jpeg'
import international_sberkassa_FX from '../../assets/other-services/international-sberkassa-fx.png'

// services icons
import cashIcon from '../../assets/other-services/servicesIcons/money-cash-dollar.svg'
import clockIcon from '../../assets/other-services/servicesIcons/time-clock.svg'
import walletIcon from '../../assets/other-services/servicesIcons/wallet.svg'
import percentageIcon from '../../assets/other-services/servicesIcons/percent.svg'
import { t } from 'i18next'

function OtherServices() {
  const heroData = {
    img: hero_img,
    title: t('other_services.title'),
    description: t('other_services.hero'),
    btnLabel: t('buttons.learn_more_2'),
    btnLink: '/contact',
    isHero: true,
  }

  // cashing Bank Checks section data
  const cashingBankChecksData = {
    img: cash_machine,
    title: t('other_services.check_cashing.title'),
    description: t('other_services.check_cashing.desc'),
    bgGradient: true,
    textFirst: true,
    btnLabel: t('buttons.learn_more'),
    btnLink: '/contact',
  }

  // International Acquiring section data
  const internationalAcquiringData = {
    img: international_acquiring,
    title: t('other_services.international_acquiring.title'),
    description: t('other_services.international_acquiring.desc'),
    bgGradient: false,
    textFirst: false,
    btnLabel: t('buttons.learn_more'),
    btnLink: '/contact',
    showPaymentMethods: true,
  }

  // International Acquiring section data
  const preciousMetalsOperationsData = {
    img: precious_metals_operations,
    title: t('other_services.precious_metals.title'),
    description: t('other_services.precious_metals.desc'),
    bgGradient: true,
    textFirst: true,
    btnLabel: t('buttons.learn_more'),
    btnLink: '/contact',
  }

  // International Acquiring section data
  const internationalSberKassaFXData = {
    img: international_sberkassa_FX,
    title: t('other_services.FX.title'),
    description: t('other_services.FX.desc'),
    bgGradient: false,
    textFirst: false,
    btnLabel: t('buttons.learn_more'),
    btnLink: '/contact',
  }

  return (
    <div className={s.other_page}>
      {/* hero section */}
      <div id='deposit-account'>
        <SplitCard {...heroData} />
      </div>

      {/* Advantages and benefits */}
      <div className={`${s.benefits} container`}>
        {/* head title */}
        <div className={s.head}>
          <div className={s.left}>
            <h2>{t('other_services.advantages_and_benefits.title')}</h2>
            <p>{t('other_services.advantages_and_benefits.desc')}</p>
          </div>
          <div className={s.right}>
            <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
          </div>
        </div>
        {/* cards */}
        <div className={s.cards}>
          {/* left img */}
          <img src={dollar_tree} alt='dollar tree' />
          {/* right cards */}
          <div className={s.right}>
            {/* right top */}
            <div className={s.top}>
              <h3>{t('other_services.advantages_and_benefits.card_1_title')}</h3>
              <p>{t('other_services.advantages_and_benefits.card_1_text')}</p>
            </div>
            {/* right bottom */}
            <div className={s.bottom}>
              <h3>{t('other_services.advantages_and_benefits.card_2_title')}</h3>
              <p>{t('other_services.advantages_and_benefits.card_2_text')}</p>
            </div>
          </div>
        </div>
        {/* button for mobile */}
        <div className={s.mobile_btn}>
          <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
        </div>
      </div>

      {/* services section */}
      <div className={`${s.services} container`}>
        <h2>{t('other_services.international_sberKassa.title')}</h2>
        <div className={s.cards_row1}>
          <div className={s.card}>
            <img src={cashIcon} alt='cash dollar' />
            <h3>{t('other_services.international_sberKassa.card_1_t')}</h3>
            <p>{t('other_services.international_sberKassa.card_1_c')}</p>
          </div>
          <div className={s.card}>
            <img src={clockIcon} alt='time clock' />
            <h3>{t('other_services.international_sberKassa.card_2_t')}</h3>
            <p>{t('other_services.international_sberKassa.card_2_c')}</p>
          </div>
          <div className={s.card}>
            <img src={walletIcon} alt='wallet icon' />
            <h3>{t('other_services.international_sberKassa.card_3_t')}</h3>
            <p>{t('other_services.international_sberKassa.card_3_c')}</p>
          </div>
        </div>
        <div className={s.cards_row2}>
          <div className={s.card}>
            <img src={percentageIcon} alt='percentage icon' />
            <h3>{t('other_services.international_sberKassa.card_4_t')}</h3>
            <p>{t('other_services.international_sberKassa.card_4_c')}</p>
          </div>
          <div className={s.card}>
            <img src={percentageIcon} alt='percentage icon' />
            <h3>{t('other_services.international_sberKassa.card_5_t')}</h3>
            <p>{t('other_services.international_sberKassa.card_5_c')}</p>
          </div>
        </div>
      </div>

      {/* bank checks */}
      <div id='cashing-checks' className={s.padding_section}>
        <SplitCard {...cashingBankChecksData} />
      </div>

      {/* International Acquiring */}
      <div id='international-acquiring' className={s.padding_section}>
        <SplitCard {...internationalAcquiringData} />
      </div>

      {/* note section */}
      <div className='container'>
        <p className={s.note}>
          {t('other_services.quote._1')} <span className={s.light_blue}>{t('other_services.quote._2')}</span>{' '}
          {t('other_services.quote._3')}
        </p>
      </div>

      {/* Precious Metals Operations section */}
      <div id='operations-metals' className={s.padding_section}>
        <SplitCard {...preciousMetalsOperationsData} />
        {/* cards */}
        <div className={`${s.gold_cards} container`}>
          <div className={s.card}>
            <h4>01</h4>
            <p dangerouslySetInnerHTML={{ __html: t('other_services.precious_metals.card1') }}></p>
          </div>
          <div className={s.card}>
            <h4>02</h4>
            <p>{t('other_services.precious_metals.card2')}</p>
          </div>
          <div className={s.card}>
            <h4>03</h4>
            <p>{t('other_services.precious_metals.card3')}</p>
          </div>
        </div>
      </div>

      {/* International SberKassa FX */}
      <div id='sberkassa-fx' className={s.padding_section}>
        <SplitCard {...internationalSberKassaFXData} />
      </div>

      {/* forex trading offers */}
      <div className={`${s.forex} container`}>
        <h3>{t('other_services.FX.subtitle')}</h3>
        <div className={s.offers}>
          <div className={s.offer}>
            <p className={s.number}>01.</p>
            <p className={s.line}>{t('other_services.FX.line1')}</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>02.</p>
            <p className={s.line}>{t('other_services.FX.line2')}</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>03.</p>
            <p className={s.line}>{t('other_services.FX.line3')}</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>04.</p>
            <p className={s.line}>{t('other_services.FX.line4')}</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>05.</p>
            <p className={s.line}>{t('other_services.FX.line5')}</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>06.</p>
            <p className={s.line}>{t('other_services.FX.line6')}</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>07.</p>
            <p className={s.line}>{t('other_services.FX.line7')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtherServices
