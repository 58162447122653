import React from 'react';
import './MyBtn.css';

const MyBtn = ({
    type, 
    title, 
    icon, 
    large, 
    medium, 
    small, 
    buttonType, 
    localDisable, 
    primary = true, 
    secondary, 
    onClick, 
    style, 
    ...props
}) => {
    const disabled = false; 

    let className = 'my_btn';
    if (large) className += ' large';
    if (medium) className += ' medium';
    if (small) className += ' small';
    if (disabled || localDisable) className += ' disabled';
    if (buttonType === 'confirm') className += ' confirm';
    if (buttonType === 'error') className += ' error';
    if (secondary) className += ' secondary';
    else if (primary) className += ' primary';

    return (
        <button
            className={className}
            disabled={disabled ? disabled : localDisable}
            onClick={onClick}
            style={style}
            {...props}
        >
            {title}
            {icon && <img className="img" src={icon} alt="" />}
            {/* {primary && <img className="arrow" src={RedArrow} alt="" />}
            {secondary && <img className="arrow" src={WhiteArrow} alt="" />} */}
        </button>
    );
};

export default MyBtn;