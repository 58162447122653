import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next) // Initialize i18next with React
  .use(LanguageDetector) // Detect user language
  .init({
    resources: {
      en: {
        translation: {
          page_title: 'International SberKassa',
          buttons: {
            login: 'Log In',
            learn_more: 'Learn More',
            open_account: 'Open an Account',
            submit: 'Submit',
            loading: 'Loading...',
            order_card: 'Order a Card',
            learn_more_2: 'Learn More',
            send: 'Send',
            contact_us: 'Contact Us',
          },
          popups:{
            sucsess: {
              title: "Success!",
              msg: "Your application has been successfully submitted.",
            },
            error: {
              title: "Error!",
              msg: "Something went wrong. Please try again later.",
            },
          },
          header: {
            home: 'Home',
            about_the_bank: 'About the Bank',
            rates_and_services: 'Rates and <br/>Services',
            corporate_clients: 'Corporate <br/>Clients',
            individual_clients: 'Individual <br/>Clients',
            payment_cards: 'Payment <br/>Cards',
            cryptocurrency_operations: 'Cryptocurrency <br/>Operations',
            other_services: 'Other Services',
            dd: {
              deposit_account: 'Deposit Account',
              cashing_of_bank_checks: 'Cashing of Bank Checks',
              international_acquiring: 'International Acquiring',
              operations_with_precious_metals: 'Operations with Precious Metals',
              international_sberKassa_FX: 'International SberKassa FX',
            },
          },
          footer: {
            general: {
              title: 'General',
              home: 'Home',
              about_bank: 'About the Bank',
              bank_tariffs: 'Bank Tariffs',
              credentials: 'Credentials',
            },
            services: {
              title: 'Services',
              corporate_clients: 'Corporate Clients',
              individual_clients: 'Individual Clients',
              cryptocurrency_operations: 'Cryptocurrency Operations',
              cashing_checks: 'Cashing of Bank Checks',
              international_acquiring: 'International Acquiring',
              precious_metals: 'Operations with Precious Metals',
              FX: 'International SberKassa FX',
            },
            information: {
              title: 'Information',
              license_number: 'License Number: B2023080',
              issue_date: 'Issue Date: 02.05.2022',
              validity: 'Validity: Indefinite',
              issuing_authority: 'Issuing Authority:<br/>Mwali International Services Authority, Fomboni, Union of Comoros',
            },
            contacts: {
              title: 'Contacts',
              address_1_note: '(Individual client services are provided by appointment)',
            },
            copyright: 'All rights reserved © 2024 International SberKassa',
          },
          home: {
            title: 'A BANK FOR YOUR FINANCIAL SUCCESS',
            hero: "Our bank promotes the economic and social development of residents of CIS countries, helping them successfully enter international markets. We support the growth and strengthening of our clients' businesses by providing them with a wide range of high-quality banking services and implementing advanced banking technologies.",
            quote_1: {
              _1: 'International SberKassa is a way to confidently advance',
              _2: 'Your Business towards success',
            },
            bank_today: {
              title: 'BANK TODAY',
              subtitle:
                'International SberKassa has been conducting operations since May 2022. We serve individual and corporate clients of various ownership forms and business sectors.',
              line_1:
                "The bank's economic growth is based on providing clients with a wide range of high-tech banking products and services that meet international standards.",
              line_2:
                'The bank is committed to every Russian-speaking client and guarantees the success of their payments, regardless of their location in the world.',
              line_3:
                'A vast network of partner correspondent banks enables payments worldwide, tailored to the specific needs of each client.',
              line_4:
                'With International SberKassa, you can confidently conduct transactions, invest in real estate, and effectively grow your capital.',
            },
            advantage_pkg: {
              title: 'OUR ADVANTAGE PACKAGE',
              subtitle:
                'Unlock new horizons for your business with our exclusive financial services package, designed for your security and success. Choose us, and you will take your finances into a world of stability and prosperity.',
              card_1: '01. Global Platform',
              card_1_text:
                'We support entrepreneurs from CIS countries and foster their economic growth in the international market, providing access to leading global banking services.',
              card_2: '02. Wide Range of Services',
              card_2_text:
                'We offer clients a variety of high-quality banking services, including financing, investment, and account management.',
              card_3: '03. Innovative Technologies',
              card_3_text: 'We utilize modern banking technologies to ensure convenience and efficiency for our clients.',
              card_4: '04. Security and Reliability',
              card_4_text:
                "We conduct banking operations with the highest level of security and reliability, safeguarding our clients' interests.",
            },
            quote_2: {
              _1: 'For us',
              _2: 'every client is',
              _3: 'an important',
              _4: 'client!',
            },
            contact: {
              title: 'Take advantage of a free consultation with a specialist',
              name: 'Full Name',
              name_PH: 'Enter your full name',
              name_error: 'Full name is required',
              email: 'Email',
              email_PH: 'Enter your email',
              email_error: 'Invalid email address',
              phone: 'Phone',
              phone_PH: 'Enter your phone number',
              phone_error: 'Phone number is required',
            },
          },
          about_bank: {
            title: 'about the bank',
            hero: 'International SberKassa is a modern Bank serving individual and corporate clients of various ownership forms and business sectors. <br/>The Bank specializes in working with high-risk clients, providing the opportunity to open accounts online, ensuring convenience and security for its clients.',
            bank_offering: {
              title: 'A bank offering innovative solutions',
              subtitle_1:
                "The Bank's business development is based on providing clients with a wide range of high-tech banking products and services at international standards.",
              subtitle_2:
                'International SberKassa continuously studies and forecasts client needs, seeks new solutions, employs advanced technical means for storage, protection, processing, and transmission of information, and minimizes bureaucratic procedures, allowing for the maximum reduction of time for checks, payments, and all types of settlements.',
              subtitle_3:
                "The foundation of the Bank's relationship with clients is the principle of partnership. The Bank is focused on long-term and mutually beneficial cooperation.",
              card_1:
                'Banking supervision of the financial organization "International SberKassa", reg. No: B2023080, is carried out by Mwali International Services Authority, BP 724, Fomboni, autonomous region of the Union of Comoros, East Africa.',
              card_2: "The Bank's authorized capital is 1,437,714,201.00 US Dollars",
              card_3:
                'Contact details of the institution supervising financial institutions in the Comoros: www.mwaliregistrar.com misa@mwaliregistrar.com Calls are accepted 24/7.',
            },
            quote: {
              _1: 'International',
              _2: 'SberKassa accepts',
              _3: 'applications for opening',
              _4: 'accounts online',
            },
          },
          tariffs_and_services: {
            title: 'Services for all<br/>occasions',
            slider: {
              _1: 'Opening a Corporate Account with personal details.',
              _2: 'Opening a General Corporate Account (with bank details).',
              _3: 'Opening an Individual Account with personal details.',
              _4: 'Opening a General Individual Account (with bank details).',
              _5: 'International Transfers to over 200 Countries.',
              _6: 'Opening a Deposit Account.',
              _7: 'Cryptocurrency Transactions.',
              _8: 'Prepaid Bank Cheques.',
              _9: 'International Acquiring.',
              _10: 'Transactions with Precious Metals.',
              _11: 'Online Banking SberCredit FX',
            },
            table: {
              title: 'Beneficial Tariffs',
              name: 'Name',
              amount: 'Amount',
              line_01_name: 'Commission for opening a corporate account:',
              line_01_amount: 'Free',
              line_02_name: 'Commission for opening an individual account:',
              line_02_amount: 'Free',
              line_03_name: 'SWIFT, SEPA Corporate Current Account:',
              line_03_amount: 'Minimum deposit amount:<br/>10,000 EUR',
              line_04_name: 'SWIFT, SEPA Corporate Pool Account:',
              line_04_amount: 'Minimum deposit amount:<br/>5,000 EUR',
              line_05_name: 'SWIFT, SEPA Individual Account:',
              line_05_amount: 'Minimum deposit amount:<br/>5,000 EUR',
              line_06_name: 'SWIFT, SEPA Individual Pool Account:',
              line_06_amount: 'Minimum deposit amount:<br/>3,000 EUR',
              line_07_name: 'Opening a Deposit Account:<br/>Minimum deposit amount:',
              line_07_amount: '500 EUR<br/>10,000 EUR',
              line_08_name: 'Compliance Check:',
              line_08_amount: 'Free',
              line_09_name: 'Maintenance of an active account:',
              line_09_amount: '30 EUR<br/>Monthly',
              line_10_name: 'Internet banking maintenance:',
              line_10_amount: 'Free',
              line_11_name: 'High-risk client servicing:',
              line_11_amount: "(The rate is set by the Bank depending on the client's risk level)",
              line_12_name: 'Individual client management:',
              line_12_amount: 'Included in the account opening fee',
              line_13_name:
                "Closure of a multi-currency current account for a client not complying with the Bank's Client Policy:",
              line_13_amount: '100 EUR',
              line_14_name: 'Preliminary document/compliance check:',
              line_14_amount: 'Free',
              line_15_name: 'Internal bank transfers (all currencies):',
              line_15_amount: 'Free',
              line_16_name: 'Investigation and manual processing of incoming payments:',
              line_16_amount: '50 EUR',
              line_17_name: 'Withdrawal or amendment of payment order:',
              line_17_amount: '30 EUR',
              line_18_name: 'Currency conversion operations:',
              line_18_amount: 'Free',
              line_19_name: 'Cryptocurrency conversion operations:',
              line_19_amount: 'Requires approval',
              line_20_name: 'International transfer, incoming/outgoing:',
              line_20_amount: '0.30%<br/>(Min. 50 EUR, Max. 2000 EUR)',
              line_21_name: 'Issuance of a virtual card:',
              line_21_amount: '300 EUR',
              line_22_name: 'Issuance of a physical card:',
              line_22_amount: '400 EUR',
              line_23_name: 'Prepaid bank cheque:',
              line_23_amount: '100 EUR',
            },
          },
          corporate_clients: {
            title: 'Corporate Clients',
            hero: 'International SberKassa provides corporate clients with the opportunity to achieve their goals and objectives in the shortest possible time.<br/><br/>By studying your activities and tasks, we can offer the most advantageous solutions, as well as increase the profitability of your operations by optimizing taxation.',
            acc_opening: 'Account Opening',
            desc: 'The process of opening a Corporate Account varies from 3 to 20 banking days, depending on the type of account requested.',
            card_1_title: 'Corporate account with personal IBAN number provision',
            card_1_text: 'Waiting time: 3-5 banking days',
            card_2_title: 'General corporate account',
            card_2_text: 'Waiting time: 5-20 banking days',
            docs: 'Documents',
            t_title: 'List of necessary documents for opening a Corporate Account',
            line1: 'Certificate of Registration',
            line2: 'Copy of the founding documents',
            line3: 'Document(s) confirming the appointment of the director(s) and secretary of the company (if applicable)',
            line4: 'Copy of the corporate register (which includes the register of shareholders, directors, and secretary)',
            line5: "Document confirming the location of the company's legal address",
            line6: 'Certificate of good standing (if the company was registered more than 12 months ago)',
            line7: 'Valid license (if applicable)',
            line8: 'Notarized copy of the current passport of the beneficiary',
            line9: 'Original or certified copy of a utility bill / bank statement',
            line10: 'Power of attorney (if available)',
            line11: 'Fully completed and signed application form for opening a corporate bank account at International SberKassa',
            line12: 'Personal Resume',
          },
          physical_clients: {
            title: 'Individual Clients',
            hero: 'Gain the ability to manage your capital remotely with the support of a personal manager 24/7.',
            acc_opening: 'Account Opening',
            desc: 'Opening an account for individuals is done entirely online; the list of necessary documents must be provided in good quality with notarisation.',
            card_1_title: 'Personal Account with individual details',
            card_1_text: 'Waiting time: 7-15 banking days',
            card_2_title: 'Personal Account with shared details',
            card_2_text: 'Waiting time: 1-5 banking days',
            docs: 'Documents',
            t_title: 'List of necessary documents for opening an Individual account',
            line1: 'Notarised copy of the current passport of the beneficiary',
            line2: 'Original or certified copy of a utility bill / bank statement',
            line3: 'Personal Resume',
            line4: 'Bank statement for the last 3 months',
          },
          payment_cards: {
            title: 'Debit Cards',
            hero: 'Debit cards (plastic, metal, virtual, non-personalised) allow for cashless payments, cash withdrawals, transfers from card to card, as well as other operations upon request through your Online Banking.',
            v_card: {
              title: 'Virtual Card',
              line1: '01 Cash withdrawal from a bank card at ATMs',
              line2: '02 Transfer of funds from card to card',
              line3: '03 Support for NFC technology with bank cards',
              line4: '04 Making online payments',
              payment_s: 'Payment System',
              master_c: 'MasterCard',
              currency: 'Currency',
              usd: 'USD',
              expiry_date: 'Expiry Date',
              _5y: '5 years',
              service: 'Service',
              free: 'Free of charge',
            },
            p_card: {
              title: 'Physical Card',
              line1: '01 Cash withdrawal from a bank card at ATMs',
              line2: '02 Transfer of funds from card to card',
              line3: '03 Support for NFC technology with bank cards',
              line4: '04 Making online payments',
              payment_s: 'Payment System',
              master_c: 'MasterCard',
              currency: 'Currency',
              usd: 'USD',
              expiry_date: 'Expiry Date',
              _5y: '5 years',
              service: 'Service',
              free: 'Free of charge',
            },
          },
          crypto_operations: {
            title: 'Cryptocurrency Transactions',
            hero: 'We support the concept of digitalization and decentralization of currency in the modern world. Additionally, International SberKassa offers the possibility of crypto exchange operations. We have no limits on the volumes of cryptocurrency exchange, so you can freely convert any amount. International SberKassa charges up to 2.5 percent for conversion depending on the volume.',
            gateway: 'International SberKassa provides a payment gateway for cryptocurrency payments',
            desc: 'This is a service that allows merchants to process payments in cryptocurrencies. It acts as an intermediary and performs 6 main functions:',
            card1: 'Sends transaction data to the blockchain and registers confirmations from nodes',
            card2: 'Provides confirmation of payment processing for both parties',
            card3: 'Registers payer data',
            card4: "Converts received coins into fiat currency (upon merchant's request)",
            card5: 'Accepts payments from the buyer',
            card6: 'Transfers payments to the merchant',
          },
          other_services: {
            title: 'Deposit Account',
            hero: 'Deposits abroad are a reliable, simple, and legal way to reduce tax burden and preserve capital. A deposit overseas should be viewed not only as an opportunity to save and increase funds but also as a profitable investment.',
            advantages_and_benefits: {
              title: 'Advantages and Benefits',
              desc: 'Open new financial horizons with our exclusive benefits package that ensures convenience and security. Take advantage of our offerings to achieve confidence and peace of mind.',
              card_1_title: '01 Privacy',
              card_1_text:
                'International SberKassa does not disclose information about the amounts and transactions on client accounts to government entities under any circumstances.',
              card_2_title: '02 Profitability',
              card_2_text:
                'We offer up to 11% annual interest for new clients, and up to 12% annual interest for clients with a history of more than two years with our bank.',
            },
            international_sberKassa: {
              title: 'International SberKassa is ready to provide the following conditions:',
              card_1_t: 'Minimum Amount:',
              card_1_c: '10,000 EUR, USD (other currencies upon request)',
              card_2_t: 'Placement Term:',
              card_2_c: 'from 3 months to 5 years',
              card_3_t: 'Interest Payment:',
              card_3_c: 'once every 1, 3, 6, or 12 months',
              card_4_t: 'Interest rate for deposits for clients with a history of more than one year:',
              card_4_c: '11% EUR; 12% USD',
              card_5_t: 'Interest rate for deposits for clients without a history:',
              card_5_c: '10% EUR; 11% USD',
            },
            check_cashing: {
              title: "Cashier's Check Cashing",
              desc: 'International SberKassa offers a service for cashing bank checks. Cashing conditions: 100 EUR, USD + 2% of the check amount. The check cashing process depends on the issuing bank. The average waiting time ranges from 7 to 14 banking days.',
            },
            international_acquiring: {
              title: 'International Acquiring',
              desc: 'Acceptance of international payments for online business. You will be able to accept payments in over 150 countries and withdraw earned funds to your company account or your personal details, as well as to a crypto wallet. Conditions and rates are clarified with your personal manager.',
            },
            quote: {
              _1: 'We successfully handle non-standard tasks and are ready to offer',
              _2: 'guarantees of better conditions compared',
              _3: 'to our competitors.',
            },
            precious_metals: {
              title: 'Transactions with Precious Metals',
              desc: 'Purchasing gold bars is a classic way to invest in gold for capital preservation and protection. Approximately 1000 tons of gold bars are purchased worldwide each year. Research shows that the optimal investment volume in gold is 2–10% of the total portfolio value, depending on the risk level. This approach helps strengthen portfolio stability and increase its profitability in the long term.',
              card1:
                'When purchasing physical gold with delivery, you can choose your preferred bar weight – 5, 10, 20, 50, 100, 250, 500 grams, 1 ounce, or buy standard bars weighing 1 kilogram. The commission for smaller weight bars may differ from the standard. Please clarify the details and calculations of the transaction with your personal manager, as well as via email at gold@sberkassa-international.com',
              card2:
                'International SberKassa offers the opportunity to purchase gold bars directly from the German refining plant C.HAFNER GmbH + Co. KG.',
              card3:
                'We provide the opportunity to purchase gold of 999.9 purity in LBMA standard bars (London Bullion Market Association) with the option of storage in the bank – in a general vault or in an individual safe deposit box. You can also take the bars and store them yourself. Each bar meets industry standards.',
            },
            FX: {
              title: 'International SberKassa FX',
              desc: 'Acceptance of international payments for online business. You will be able to accept payments in over 150 countries and withdraw earned funds to your company account or to your personal account, as well as to a crypto wallet.',
              subtitle: 'We offer our clients the opportunity to trade on the Forex market in real-time conditions.',
              line1: 'Trading currency pairs, metals, and oil',
              line2: 'Liquidity from leading banks and market makers',
              line3: 'Instant order execution, thanks to STP technology',
              line4: 'Narrow spreads and competitive quotes from key liquidity providers',
              line5: 'Support for scalping strategies',
              line6: 'No restrictions on stop orders and limit orders',
              line7:
                'Convenient account opening, deposit, and withdrawal of funds. After passing verification, transactions are carried out by bank transfer based on the corresponding instructions.',
            },
          },
          contact: {
            title: 'Contacts',
            hero: 'Addresses of branches and contact numbers of the bank. In this section, you can find phone numbers and email addresses for contacting the bank. You can also leave us your inquiries or suggestions.',
            address: 'Address',
            representative_office: 'Representative Office:',
            note: '(Services for individuals are provided by appointment only.)',
            head_office: 'Head Office:',
            working_hours: 'Working Hours',
            working_hours_t: 'Mon-Fri 09:00–18:00, Lunch 13:00–14:00',
            email: 'Email',
            website: 'Website',
            phone: 'Compliance Service Phone',
            form: {
              name: 'Full Name',
              name_PH: 'Enter Full Name',
              name_error: 'Full name is required',
              email: 'Email',
              email_PH: 'Enter Email',
              email_error: 'Invalid email address',
              phone: 'Phone',
              phone_PH: 'Enter Phone Number',
              phone_error: 'Phone number is required',
              feedback: 'Feedback',
              feedback_PH: 'Enter Message',
              feedback_error: 'Message is required',
            },
          },
          login: {
            we_are_glad: "We are glad to welcome you again!",
            password: "password",
            forgot_password: "Forgot password",
            dont_have_account: "Don't have an account?",
            signup: "Sign up",
          },
        },
      },
      ru: {
        translation: {
          page_title: 'Международная СберКасса',
          buttons: {
            login: 'Войти',
            learn_more: 'Подробнее',
            open_account: 'Открыть счет',
            submit: 'Отправить',
            loading: 'Отправка...',
            order_card: 'Заказать карту',
            learn_more_2: 'Узнать подробнее',
            send: 'Отправить',
            contact_us: 'Cвязаться',
          },
          popups:{
            sucsess: {
              title: "Успешно!",
              msg: "Ваша заявка была отправлена.",
            },
            error: {
              title: "Ошибка!",
              msg: "Что-то пошло не так. Пожалуйста, повторите попытку позже.",
            },
          },
          header: {
            home: 'Главная',
            about_the_bank: 'О Банке',
            rates_and_services: 'Тарифы и <br/>Услуги',
            corporate_clients: 'Корпоративным <br/>Клиентам',
            individual_clients: 'Физическим <br/>Клиентам',
            payment_cards: 'Платежные <br/>Карты',
            cryptocurrency_operations: 'Операции с <br/>Криптовалютой',
            other_services: 'Другие услуги',
            dd: {
              deposit_account: 'Депозитный счет',
              cashing_of_bank_checks: 'Обналичивание банковских чеков',
              international_acquiring: 'Международный эквайринг',
              operations_with_precious_metals: 'Операции с драгоценными металлами',
              international_sberKassa_FX: 'Меджународная СберКасса FX',
            },
          },
          footer: {
            general: {
              title: 'Общее',
              home: 'Главная',
              about_bank: 'О банке',
              bank_tariffs: 'Тарифы банка',
              credentials: 'Реквизиты',
            },
            services: {
              title: 'Услуги',
              corporate_clients: 'Корпоративным клиентам',
              individual_clients: 'Физическим клиентам',
              cryptocurrency_operations: 'Операции с криптовалютой',
              cashing_checks: 'Обналичивание банковских чеков',
              international_acquiring: 'Международный эквайринг',
              precious_metals: 'Операции с драгоценными металлами',
              FX: 'Международная СберКасса FX',
            },
            information: {
              title: 'Инфо',
              license_number: 'Номер Лицензии: B2023080',
              issue_date: 'Дата выдачи: 02.05.2022',
              validity: 'Срок действия: Бессрочная',
              issuing_authority:
                'Орган, выдавший лицензию:<br/>Mwali International Services Authority, Fomboni, Union of Comoros',
            },
            contacts: {
              title: 'Контакты',
              address_1_note: '(Обслуживание физических лиц осуществляется по предварительной записи)',
            },
            copyright: 'все права защищены © 2024 Международная СберКасса',
          },
          home: {
            title: 'Банк для вашего финансового успеха ',
            hero: 'Наш банк способствует экономическому и социальному развитию резидентов стран СНГ, способствуя их успешному выходу на международные рынки. Мы поддерживаем рост и укрепление бизнеса наших клиентов, предоставляя им для этого широкий спектр высококачественных банковских услуг и внедряя передовые банковские технологии.',
            quote_1: {
              _1: 'Международная СберКасса — это способ уверенного продвижения',
              _2: 'Вашего Бизнеса в сторону успеха',
            },
            bank_today: {
              title: 'Банк сегодня',
              subtitle:
                'Международная СберКасса осуществляет операции с Мая 2022 года. Мы обслуживаем индивидуальных и корпоративных клиентов различных форм собственности и направлений деятельности.',
              line_1:
                'Экономическое развитие Банка основывается на предоставлении клиентам широкого спектра высокотехнологичных банковских продуктов и услуг, соответствующих международным стандартам.',
              line_2:
                'Банк лоялен к каждому русскоязычному клиенту и гарантирует успешность его платежей, независимо от того, в какой точке мира он находится.',
              line_3:
                'Широкая сеть партнерских банков-корреспондентов позволяет осуществлять платежи по всему миру с учетом любых пожеланий клиента.',
              line_4:
                'Вместе с Международной СберКассой вы сможете с уверенностью совершать сделки, инвестировать в недвижимость, а также приумножать свой капитал.',
            },
            advantage_pkg: {
              title: 'Наш пакет преимуществ',
              subtitle:
                'Вы откроете для своего бизнеса новые горизонты с нашим эксклюзивным пакетом финансовых услуг, созданного для вашей безопасности и успеха. Выберите нас и вы перенесете ваши финансы в мир стабильности и процветания.',
              card_1: '01. Глобальная платформа',
              card_1_text:
                'Поддерживаем предпринимателей стран СНГ и обеспечиваем их экономическое развитие на международном рынке, обеспечивая им доступ к передовым банковским услугам мирового масштаба.',
              card_2: '02. Широкий спектр услуг',
              card_2_text:
                'Предлагаем клиентам разнообразные банковские услуги высокого качества, включая услуги по финансированию, инвестированию, и обслуживанию счетов',
              card_3: '03. Инновационные технологии',
              card_3_text:
                'Используем современные банковские технологии, чтобы обеспечить удобство и безопасность для наших клиентов',
              card_4: '04. Безопасность и надежность',
              card_4_text:
                'Осуществляем банковские операции с высоким уровнем безопасности и надежности, защищая интересы наших клиентов',
            },
            quote_2: {
              _1: 'Для нас',
              _2: 'каждый клиент',
              _3: 'важный',
              _4: 'клиент!',
            },
            contact: {
              title: 'Воспользуйтесь бесплатной консультацией специалиста',
              name: 'ФИО',
              name_PH: 'Введите ФИО',
              name_error: 'ФИО обязательно для заполнения',
              email: 'Email',
              email_PH: 'Введите Email',
              email_error: 'Некорректный адрес электронной почты',
              phone: 'Телефон',
              phone_PH: 'Введите номер телефона',
              phone_error: 'Номер телефона обязателен для заполнения',
            },
          },
          about_bank: {
            title: 'о банке ',
            hero: 'Международная СберКасса — это современный Банк, обслуживающий индивидуальных и корпоративных клиентов различных форм собственности и направлений деятельности. <br/>Банк специализируется на работе с клиентами повышенного риска, предоставляет возможность открывать счета онлайн, обеспечивая удобство и безопасность для своих клиентов.',
            bank_offering: {
              title: 'Банк, предлагающий инновационные решения',
              subtitle_1:
                'Развитие бизнеса Банка строится на предоставлении клиентам широкого спектра высокотехнологических банковских продуктов и услуг на уровне международных стандартов.',
              subtitle_2:
                'Международная СберКасса постоянно изучает и прогнозирует потребности клиентов, осуществляет поиск новых решений, применяет передовые технические средства хранения, защиты, обработки и передачи информации, минимизирует бюрократические процедуры, что позволяет максимально сократить время прохождения проверок, платежей и всех видов расчетов.',
              subtitle_3:
                'В основе взаимоотношений Банка с клиентами лежит принцип партнерства. Банк ориентирован на долгосрочное и взаимовыгодное сотрудничество.',
              card_1:
                'Банковский надзор за деятельностью финансовой организации «Международная СберКасса», рег. No: B2023080, осуществляет Mwali International Services Authority, BP 724, г. Фомбони, автономный регион Союза Коморских Островов Мвали, Восточная Африка.',
              card_2: 'Уставной капитал Банка 1,437,714,201.00 Долларов США',
              card_3:
                'Контактные данные учреждения по надзору финансовых институтов на территории Коморских Островов: www.mwaliregistrar.com misa@mwaliregistrar.com Звонки принимаются круглосуточно.',
            },
            quote: {
              _1: 'Международная СберКасса',
              _2: 'принимает заявки',
              _3: 'на открытие счетов',
              _4: 'онлайн',
            },
          },
          tariffs_and_services: {
            title: 'Услуги на  все<br/>случаи жизни',
            slider: {
              _1: 'Открытие Корпоративного Счета с персональными реквизитами.',
              _2: 'Открытие Общего Корпоративного Счета (с реквизитами банка).',
              _3: 'Открытие Индивидуального Счета с персональными реквизитами.',
              _4: 'Открытие Общего Индивидуального Счета (с реквизитами банка).',
              _5: 'Международные Переводы в более 200 Стран.',
              _6: 'Отĸрытие Депозитного Счета.',
              _7: 'Операции с Криптовалютами.',
              _8: 'Предоплаченные Банковские Чеки.',
              _9: ' Международный Эĸвайринг.',
              _10: 'Операции с Драгоценными Металлами.',
              _11: 'Онлайн Банĸинг СберКредит FX',
            },
            table: {
              c: '<div class="lightText"></div>',
              title: 'Выгодные Тарифы',
              name: 'Наименование',
              amount: 'Сумма',
              line_01_name: 'Комиссия за открытие корпоративного счета:',
              line_01_amount: 'Бесплатно',
              line_02_name: 'Комиссия за открытие индивидуального счета:',
              line_02_amount: 'Бесплатно',
              line_03_name: 'SWIFT, SEPA Корпоративный Расчетный счет:',
              line_03_amount: '<div class="lightText">Сумма минимального депозита:<br/>10,000 EUR</div>',
              line_04_name: 'SWIFT, SEPA Корпоративный Пул-счет:',
              line_04_amount: '<div class="lightText">Сумма минимального депозита:<br/>5,000 EUR</div>',
              line_05_name: 'SWIFT, SEPA  Индивидуальный счет:',
              line_05_amount: '<div class="lightText">Сумма минимального депозита:<br/>5,000 EUR</div>',
              line_06_name: 'SWIFT, SEPA Индивидуальный Пул-счет:',
              line_06_amount: '<div class="lightText">Сумма минимального депозита:<br/>3,000 EUR</div>',
              line_07_name: 'Отĸрытие Депозитного Счета:<br/><div class="lightText">Минимальная сумма депозита:</div>',
              line_07_amount: '500 EUR<br/><div class="lightText">10,000 EUR</div>',
              line_08_name: 'Проверка Комплайнс:',
              line_08_amount: 'Бесплатно',
              line_09_name: 'Обслуживание активного счета:',
              line_09_amount: '30 EUR<br/><div class="lightText">Ежемесячно</div>',
              line_10_name: 'Обслуживание интернет-банкинга:',
              line_10_amount: 'Бесплатно',
              line_11_name: 'Обслуживание клиента повышенного риска:',
              line_11_amount: '(Ставку назначает Банк в зависимости от уровня риска клиента)',
              line_12_name: 'Индивидуальное ведение клиента:',
              line_12_amount: 'Входит в стоимость открытия счета',
              line_13_name: 'Закрытие мультивалютного расчётного счета клиенту, не соответствующему Клиентской политике Банка:',
              line_13_amount: '100 EUR',
              line_14_name: 'Предварительная проверка документов/комплайнс:',
              line_14_amount: 'Бесплатно',
              line_15_name: 'Внутрибанковские перечисления (все валюты):',
              line_15_amount: 'Бесплатно',
              line_16_name: 'Расследование и ручная обработка входящего платежа:',
              line_16_amount: '50 EUR',
              line_17_name: 'Отзыв или внесение изменений в платёжное распоряжение:',
              line_17_amount: '30 EUR',
              line_18_name: 'Конверсионные операции в валюте:',
              line_18_amount: 'Бесплатно',
              line_19_name: 'Конверсионные операции в криптовалюте:',
              line_19_amount: 'Требуется согласование',
              line_20_name: 'Международный перевод, входящий/исходящий:',
              line_20_amount: ' 0.30%<br/><div class="lightText">(Мин. 50 EUR, Макс. 2000 EUR)</div>',
              line_21_name: 'Выпуск виртуальной карты:',
              line_21_amount: '300 EUR',
              line_22_name: 'Выпуск физической карты:',
              line_22_amount: '400 EUR',
              line_23_name: 'Предоплаченный банковский чек:',
              line_23_amount: '100 EUR',
            },
          },
          corporate_clients: {
            title: 'Корпоративным Клиентам',
            hero: 'Международная СберКасса предоставляет возможность ĸорпоративным ĸлиентам осуществлять поставленные цели и задачи в ĸратчайшие сроĸи.<br/><br/>Изучив Вашу деятельность и задачи, мы сможем предложить наиболее выгодные решения, а таĸже увеличить доходность вашей деятельности, оптимизировав налогообложение.',
            acc_opening: 'Открытие счета',
            desc: 'Процесс отĸрытия Корпоративного Счета варьируется от 3-х до 20-ти банĸовсĸих дней, в зависимости от запрашиваемого типа Счета.',
            card_1_title: 'Корпоративный счет с предоставлением личного IBAN номера',
            card_1_text: 'Время ожидания: 3-5 банĸовсĸих дней',
            card_2_title: 'Общий корпоративный счет',
            card_2_text: 'Время ожидания: 5-20 банĸовсĸих дней',
            docs: 'Документы',
            t_title: 'Список необходимых документов для открытия Корпоративного счета',
            line1: 'Свидетельство о регистрации',
            line2: 'Копия учредительных документов',
            line3: 'Документ(ы), подтверждающий назначение директора(ов) и секретаря компании (при наличии)',
            line4: 'Копия корпоративного реестра (который включает в себя реестр акционеров, директоров и секретаря)',
            line5: 'Документ, подтверждающий местонахождение юридического адреса компании',
            line6: 'Свидетельство о хорошей репутации (в случае, если компания была зарегистрирована более 12 месяцев назад)',
            line7: 'Действующая лицензия (если применимо)',
            line8: 'Нотариально заверенная копия действующего паспорта бенефициара',
            line9: 'Оригинал или заверенная копия счета за коммунальные услуги / выписка из банка',
            line10: 'Доверенность (при наличии)',
            line11:
              'Полностью заполненная и подписанная анкета для открытия корпоративного банковского счета в Международной СберКассе',
            line12: 'Личное резюме',
          },
          physical_clients: {
            title: 'Физическим Клиентам',
            hero: 'Получите возможность управлять своим ĸапиталом удаленно и с поддержĸой персонального менеджера 24/7.',
            acc_opening: 'Открытие счета',
            desc: 'Отĸрытие счета для физичесĸих лиц осуществляется полностью на онлайн основе, списоĸ необходимых доĸументов должен быть предоставлен в хорошем ĸачестве с нотариальным заверением.',
            card_1_title: 'Персональный Счет с индивидуальными реквизитами',
            card_1_text: 'Время ожидания: 7-15 банĸовсĸих дней',
            card_2_title: 'Персональный Счет  с общими реквизитами',
            card_2_text: 'Время ожидания: 1-5 банĸовсĸих дней',
            docs: 'Документы',
            t_title: 'Список необходимых документов для открытия Индивидуального cчета',
            line1: 'Нотариально заверенная копия действующего паспорта бенефициара',
            line2: 'Оригинал или заверенная копия счета за коммунальные услуги / выписка из банка',
            line3: 'Личное резюме',
            line4: 'Выписĸа из Банĸа за последние 3 месяца',
          },
          payment_cards: {
            title: 'Дебетовые Карты',
            hero: 'Дебетовые карты (пластиковые, металлические, виртуальные, неименные) — возможность осуществлять безналичные расчеты, снятие наличных, перевод с карты на карту, а также другие операции по запросу через Ваш Онлайн-Банкинг.',
            v_card: {
              title: 'Виртуальная карта',
              line1: '01 Снятие наличных с банковской карты в банкоматах',
              line2: '02 Перевод денежных средств с карты на карту',
              line3: '03 Поддержка банковской картой технологии NFC',
              line4: '04 Осуществление онлайн платежей',
              payment_s: 'Платежная система',
              master_c: 'MasterCard',
              currency: 'Валюта',
              usd: 'USD',
              expiry_date: 'Срок действия',
              _5y: '5 лет',
              service: 'Обслуживание',
              free: 'Бесплатно',
            },
            p_card: {
              title: 'Физическая карта',
              line1: '01 Снятие наличных с банковской карты в банкоматах',
              line2: '02 Перевод денежных средств с карты на карту',
              line3: '03 Поддержка банковской картой технологии NFC',
              line4: '04 Осуществление онлайн платежей',
              payment_s: 'Платежная система',
              master_c: 'MasterCard',
              currency: 'Валюта',
              usd: 'USD',
              expiry_date: 'Срок действия',
              _5y: '5 лет',
              service: 'Обслуживание',
              free: 'Бесплатно',
            },
          },
          crypto_operations: {
            title: 'Операции с Криптовалютой',
            hero: 'Мы поддерживаем ĸонцепцию цифровизации и децентрализации валюты в современном мире. Также Международная СберКасса предоставляет возможность криптообменных операций. У нас нет ограничений на объемы обмена криптовалют, поэтому вы можете свободно конвертировать любую сумму. Международная СберКасса взимает до 2,5 процентов за конвертацию в зависимости от объема.',
            gateway: 'Международная СберКасса предоставляет платежный шлюз для криптоплатежей',
            desc: 'Это сервис, который позволяет продавцам обрабатывать платежи в криптовалютах. Он действует как посредник и выполняет 6 основных функций:',
            card1: 'Отправляет данные транзакции в блокчейн и регистрирует подтверждения от узлов',
            card2: 'Предоставляет подтверждение обработки платежа для обеих сторон',
            card3: 'Регистрирует данные плательщика',
            card4: 'Конвертирует полученные монеты в фиатные деньги (по запросу мерчанта)',
            card5: 'Принимает платежи от покупателя',
            card6: 'Переводит платежи мерчанту',
          },
          other_services: {
            title: 'Депозитный счет',
            hero: 'Депозиты за рубежом — это надежный, простой и законный способ снизить налоговое бремя и сохранить капитал. Депозит за границей нужно рассматривать не только как возможность сохранить и приумножить средства, но и как выгодное вложение.',
            advantages_and_benefits: {
              title: 'Преимущества и выгода',
              desc: 'Откройте новые горизонты финансов с нашим эксклюзивным пакетом преимуществ, который обеспечит вам удобство и безопасность. Воспользуйтесь нашими возможностями для достижения уверенности и спокойствия.',
              card_1_title: '01 Приватность',
              card_1_text:
                'Международная СберКасса не разглашает сведения о сумме и движении по счетам клиента государственным структурам ни при каких обстоятельствах.',
              card_2_title: '02 Доходность',
              card_2_text:
                'Мы предлагаем до 11% годовых новым Клиентам, а также до 12% годовых Клиентам с историей в нашем Банке более двух лет.',
            },
            international_sberKassa: {
              title: 'Международная CберКасса готова предоставить следующие условия:',
              card_1_t: 'Минимальная сумма:',
              card_1_c: '10 000 EUR, USD (другие валюты по запросу)',
              card_2_t: 'Срок размещения:',
              card_2_c: 'от 3 месяцев до 5 лет',
              card_3_t: 'Выплата процентов:',
              card_3_c: 'раз в 1, 3, 6, 12 месяцев',
              card_4_t: 'Процент выплаты по депозитам для клиентов с историей более одного года:',
              card_4_c: '11% EUR; 12% USD',
              card_5_t: 'Процент выплаты по депозитам для клиентов без истории:',
              card_5_c: '10% EUR; 11% USD',
            },
            check_cashing: {
              title: 'Обналичивание банковских чеков',
              desc: 'Международная СберКасса предлагает услугу обналичивания банĸовсĸих чеĸов. Условия обналичивания: 100 EUR, USD + 2% от суммы самого чека. Процесс обналичивания чеков зависит от Банка-эмитента. Среднее время ожидания варьируется от 7-ми до 14-ти банковских дней.',
            },
            international_acquiring: {
              title: 'Международный Эквайринг',
              desc: 'Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+ странах мира и выводить заработанные средства на счет компании или ваши персональные реквизиты, а также на криптокошелек. Условия и тарифы уточняются у вашего персонального Менеджера.',
            },
            quote: {
              _1: 'Мы успешно справляемся с нестандартными задачами и',
              _2: 'готовы предложить гарантию лучших условий',
              _3: 'по сравнению с нашими конкурентами.',
            },
            precious_metals: {
              title: 'Операции с Драгоценными Металлами',
              desc: 'Приобретение золотых слитков — это классический способ инвестирования в золото для сохранения и защиты капитала. Ежегодно в мире покупается около 1000 тонн золотых слитков. Исследования показывают, что оптимальный объем инвестиций в золото составляет 2–10% от общей стоимости портфеля, в зависимости от уровня риска. Такой подход позволяет укрепить стабильность портфеля и повысить его доходность в долгосрочной перспективе.',
              card1:
                'При покупке физического золота с доставкой, Вы можете выбрать предпочтительный вес слитка — 5, 10, 20, 50, 100, 250, 500 грамм, 1 унцию, или купить стандартные слитки весом 1 килограмм. Комиссия на слитки меньшего веса может отличаться от стандартной.<br/><br/>Детали и расчеты сделки просим уточнять у Вашего персонального Менеджера, а также через почту gold@sberkassa-international.com',
              card2:
                'Международная СберКасса предлагает возможность приобретения золотых слитков напрямую с немецкого аффинажного завода C.HAFNER GmbH + Co. KG.',
              card3:
                'Мы предоставляем возможность приобрести золото 999,9 пробы в слитках стандарта LBMA (Лондонская Биржа драгоценных металлов) с возможностью хранения в банке — в общем хранилище или в индивидуальной сейфовой ячейке. Вы также можете забрать слитки и хранить их самостоятельно. Каждый слиток соответствует отраслевым стандартам.',
            },
            FX: {
              title: 'Международная СберКасса FX',
              desc: 'Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+ странах мира и выводить заработанные средства на счет компании или на ваш персональный счет, а также на криптокошелек.',
              subtitle: 'Мы предлагаем своим Клиентам возможность торговли на рынке Forex в условиях реального времени',
              line1: 'Торговля валютными парами, металлами и нефтью',
              line2: 'Ликвидность от ведущих банков-маркетмейкеров',
              line3: 'Мгновенное исполнение ордеров, благодаря технологиям STP',
              line4: 'Узкие спреды и конкурентные котировки от ключевых поставщиков ликвидности',
              line5: 'Поддержка стратегий скальпинга',
              line6: 'Отсутствие ограничений на установки стоп-ордеров и лимит-ордеров',
              line7:
                'Удобное открытие счетов, зачисление и вывод средств. После прохождения проверки, операции осуществляются банковским платежом на основании соответствующих распоряжений',
            },
          },
          contact: {
            title: 'Контакты',
            hero: 'Адреса отделений и контактные номера банка. В этом разделе вы можете найти номера телефонов и электронные адреса для связи с банком. А так же вы можете оставить нам свои обращения или предложения.',
            address: 'Адрес',
            representative_office: 'Репрезентативный Офис:',
            note: '(Обслуживание физических лиц осуществляется по предварительной записи.)',
            head_office: 'Центральный офис:',
            working_hours: 'Режим работы',
            working_hours_t: 'Пн-Пт 09:00–18:00, Обед 13:00–14:00',
            email: 'Электронная почта',
            website: 'Сайт',
            phone: 'Телефон службы комплаенс',
            form: {
              name: 'ФИО',
              name_PH: 'Введите ФИО',
              name_error: 'ФИО обязательно для заполнения',
              email: 'Email',
              email_PH: 'Введите Email',
              email_error: 'Некорректный адрес электронной почты',
              phone: 'Телефон',
              phone_PH: 'Введите номер телефона',
              phone_error: 'Номер телефона обязателен для заполнения',
              feedback: 'Обратная связь',
              feedback_PH: 'Введите сообщение',
              feedback_error: 'Сообщение обязательно для заполнения',
            },
          },
          login: {
            we_are_glad: "Рады Вас снова приветствовать!",
            password: "Пароль",
            forgot_password: "Восстановление доступа",
            dont_have_account: "Нет аккаунта СберКассы?",
            signup: "Зарегистрироваться",
          },
        },
      },
    },
    fallbackLng: 'en', // Default language if user's language is not available
    detection: {
      order: ['localStorage', 'navigator'], // Detect language from browser settings or localStorage
      caches: ['localStorage'], // Store the selected language in localStorage
    },
    interpolation: {
      escapeValue: false, // React already protects against XSS
    },
  })

export default i18n
