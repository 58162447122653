import sberkassaLogo from '../../src/assets/headerLogo.svg'

export const handleDownloadPDF = (transactions, userData) => {
  if (!transactions.length) {
    alert('No data available to download.')
    return
  }

  const htmlContent = `
        <html>
            <head>
                <title>
                    выписка из банка
                </title>
                <style>
                    @page {
                        margin: 0;
                        }
                        
                    body {
                        margin-inline: 2rem;
                    }  
                      
                    img {
                        width: 250px;
                    }
                    
                    .user-info-panel {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: .5rem;
                        column-gap: 2rem;
                        margin-block: 1rem;
                    }
                    
                    .user-info-panel-box {
                        text-align: left;
                    }
                    
                    table {
                        position: relative;
                        border: none;
                        border-collapse: collapse;
                        width: 100%;
                        table-layout: auto;
                        font-size: 11px;
                    }
                    
                    tr th {
                        background-color: lightgray;
                    }
                    
                    th, td {
                        border: 1px solid #ddd;
                        padding: 4px;
                        text-align: left;
                        word-wrap: break-word;
                    }
                    th {
                        background-color: #f4f4f4;
                    }
                    
                    @media screen  and (min-width: 1200px) {
                        table {
                        font-size: 11px;
                        }
                    }
                    
                </style>
            </head>
            <body>
              <img src="${sberkassaLogo}" alt="Sberkassa Logo" />
              <div class="user-info-panel">
                <article class="user-info-panel-box">
                  <strong> Номер пользователя:
                    <!-- User Number -->
                  </strong>
                  <span> ${userData.firstName || 'N/A'} ${userData.lastName || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Почта:
                    <!-- Email -->
                  </strong>
                  <span> ${userData.email || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Номер телефона:
                    <!-- Phone Number -->
                  </strong>
                  <span> ${userData.phoneNumber || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Тип аккаунта:
                    <!-- Account Type -->
                  </strong>
                  <span> ${userData.accountType || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Адрес:
                    <!-- Address -->
                  </strong>
                  <span> ${userData.address || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Город:
                    <!-- City -->
                  </strong>
                  <span> ${userData.city || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Страна:
                    <!-- Country -->
                  </strong>
                  <span> ${userData.country || 'N/A'} </span>
                </article>
                <article class="user-info-panel-box">
                  <strong> Штат или провинция:
                    <!-- State or Province -->
                  </strong>
                  <span> ${userData.stateOrProvince || 'N/A'} </span>
                </article>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Перевод №</th>
                    <th>Отправитель</th>
                    <th>Получатель</th>
                    <th>Тип</th>
                    <th>Назначение</th>
                    <th>направление</th>
                    <th>Сумма</th>
                    <th>Баланс</th>
                    <th>статус</th>
                  </tr>
                </thead>
                <tbody> ${transactions
                  .map(
                    transaction => ` <tr>
                    <td>${new Date(transaction.created * 1000).toLocaleDateString('ru-RU')}</td>
                    <td>${transaction.transactionNumber}</td>
                    <td>${transaction.fromAccount}</td>
                    <td>${transaction.toAccount}</td>
                    <td>${transaction.transactionType}</td>
                    <td>${transaction.reference}</td>
                    <td>${transaction.direction}</td>
                    <td style="color:${transaction.direction === 'Входящий' ? 'green' : 'red'}"> ${
                      transaction.direction === 'Входящий' ? '+' : '-'
                    }${transaction.amount} ${transaction.currency} </td>
                    <td>${transaction.balance} ${transaction.currency}</td>
                    <td>${transaction.transactionStatus}</td>
                  </tr> `
                  )
                  .join('')} </tbody>
              </table>
            </body>        
            </html>
    `

  const newWindow = window.open('', '_blank', 'width=800,height=600')

  newWindow.document.open()
  newWindow.document.write(htmlContent)
  newWindow.document.close()
  newWindow.document.title = 'выписка из банка'

  setTimeout(() => {
    newWindow.print()
  }, 500)
}
