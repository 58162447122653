import React from 'react'
import s from './AboutBank.module.css'
import HeroWithBanner from '../../components/hero-with-banner/HeroWithBanner'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import heroBanner from '../../assets/about-bank/hero-banner.jpeg'
import robotHand from '../../assets/about-bank/robot-hand.png'
import { t } from 'i18next'

function AboutBank() {
  const heroData = {
    heroImage: heroBanner,
    title: t('about_bank.title'),
    desc: t('about_bank.hero'),
    btnLabel: t('buttons.open_account'),
    btnLink: '/register',
    bgGradient: false,
    textLeft: false,
  }

  return (
    <div className={s.about_page}>
      {/* hero section */}
      <div className={s.hero}>
        <HeroWithBanner {...heroData} />
      </div>

      {/* innovative solutions block */}
      <div class={`${s.innovative_solutions} container`}>
        {/* left side */}
        <div class={s.left}>
          <h2>{t('about_bank.bank_offering.title')}</h2>
          <div className={s.desc}>
            <p>{t('about_bank.bank_offering.subtitle_1')}</p>
            <p>{t('about_bank.bank_offering.subtitle_2')}</p>
            <p>{t('about_bank.bank_offering.subtitle_3')}</p>
          </div>
          {/* btn for lg devices only */}
          <div className={s.btn}>
            <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
          </div>
        </div>

        {/* right side */}
        <div class={s.right}>
          <img className={s.card_img} src={robotHand} alt='Innovative Solution' />
          <div className={`${s.card} ${s.gradient_bg} ${s.card_1}`}>
            <h4>01</h4>
            <p>{t('about_bank.bank_offering.card_1')}</p>
          </div>
          <div className={`${s.card} ${s.card_2} ${s.white_card}`}>
            <h4>02</h4>
            <p>{t('about_bank.bank_offering.card_2')}</p>
          </div>
          <div className={`${s.card} ${s.gradient_bg} ${s.card_3}`}>
            <h4>03</h4>
            <p>{t('about_bank.bank_offering.card_3')}</p>
          </div>
          <div className={s.btn}>
            <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' arrow />
          </div>
        </div>
      </div>

      {/* quote container */}
      <div className={`${s.quote} container`}>
        <p>
          {t('about_bank.quote._1')} <span>{t('about_bank.quote._2')}</span> {t('about_bank.quote._3')}{' '}
          <span>{t('about_bank.quote._4')}</span>
        </p>
      </div>
    </div>
  )
}

export default AboutBank
