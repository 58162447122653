import React from 'react'
import './TariffsAndServices.css'
import NewSlider from '../../components/ui/newSlider/newSlides'
import slide_img_01 from '../../assets/tariffs-and-services/slide-img-01.webp'
import slide_img_02 from '../../assets/tariffs-and-services/slide-img-02.png'
import slide_img_03 from '../../assets/tariffs-and-services/slide-img-03.jpeg'
import slide_img_04 from '../../assets/tariffs-and-services/slide-img-04.jpeg'
import { t } from 'i18next'

function TariffsAndServices() {
  const tableData = [
    { leftContent: t('tariffs_and_services.table.line_01_name'), rightContent: t('tariffs_and_services.table.line_01_amount') },
    { leftContent: t('tariffs_and_services.table.line_02_name'), rightContent: t('tariffs_and_services.table.line_02_amount') },
    { leftContent: t('tariffs_and_services.table.line_03_name'), rightContent: t('tariffs_and_services.table.line_03_amount') },
    { leftContent: t('tariffs_and_services.table.line_04_name'), rightContent: t('tariffs_and_services.table.line_04_amount') },
    { leftContent: t('tariffs_and_services.table.line_05_name'), rightContent: t('tariffs_and_services.table.line_05_amount') },
    { leftContent: t('tariffs_and_services.table.line_06_name'), rightContent: t('tariffs_and_services.table.line_06_amount') },
    { leftContent: t('tariffs_and_services.table.line_07_name'), rightContent: t('tariffs_and_services.table.line_07_amount') },
    { leftContent: t('tariffs_and_services.table.line_08_name'), rightContent: t('tariffs_and_services.table.line_08_amount') },
    { leftContent: t('tariffs_and_services.table.line_09_name'), rightContent: t('tariffs_and_services.table.line_09_amount') },
    { leftContent: t('tariffs_and_services.table.line_10_name'), rightContent: t('tariffs_and_services.table.line_10_amount') },
    { leftContent: t('tariffs_and_services.table.line_11_name'), rightContent: t('tariffs_and_services.table.line_11_amount') },
    { leftContent: t('tariffs_and_services.table.line_12_name'), rightContent: t('tariffs_and_services.table.line_12_amount') },
    { leftContent: t('tariffs_and_services.table.line_13_name'), rightContent: t('tariffs_and_services.table.line_13_amount') },
    { leftContent: t('tariffs_and_services.table.line_14_name'), rightContent: t('tariffs_and_services.table.line_14_amount') },
    { leftContent: t('tariffs_and_services.table.line_15_name'), rightContent: t('tariffs_and_services.table.line_15_amount') },
    { leftContent: t('tariffs_and_services.table.line_16_name'), rightContent: t('tariffs_and_services.table.line_16_amount') },
    { leftContent: t('tariffs_and_services.table.line_17_name'), rightContent: t('tariffs_and_services.table.line_17_amount') },
    { leftContent: t('tariffs_and_services.table.line_18_name'), rightContent: t('tariffs_and_services.table.line_18_amount') },
    { leftContent: t('tariffs_and_services.table.line_19_name'), rightContent: t('tariffs_and_services.table.line_19_amount') },
    { leftContent: t('tariffs_and_services.table.line_20_name'), rightContent: t('tariffs_and_services.table.line_20_amount') },
    { leftContent: t('tariffs_and_services.table.line_21_name'), rightContent: t('tariffs_and_services.table.line_21_amount') },
    { leftContent: t('tariffs_and_services.table.line_22_name'), rightContent: t('tariffs_and_services.table.line_22_amount') },
    { leftContent: t('tariffs_and_services.table.line_23_name'), rightContent: t('tariffs_and_services.table.line_23_amount') },
  ]

  const slidesData = [
    {
      items: [
        { title: '01', text: t('tariffs_and_services.slider._1'), link: '/corporate-clients' },
        { title: '02', text: t('tariffs_and_services.slider._2'), link: '/corporate-clients' },
        { title: '03', text: t('tariffs_and_services.slider._3'), link: '/physical-clients' },
      ],
      image: slide_img_01,
    },
    {
      items: [
        { title: '04', text: t('tariffs_and_services.slider._4'), link: '/physical-clients' },
        { title: '05', text: t('tariffs_and_services.slider._5'), link: '/other-services' },
        { title: '06', text: t('tariffs_and_services.slider._6'), link: '/other-services' },
      ],
      image: slide_img_02,
    },
    {
      items: [
        { title: '07', text: t('tariffs_and_services.slider._7'), link: '/other-services' },
        { title: '08', text: t('tariffs_and_services.slider._8'), link: '/other-services' },
        { title: '09', text: t('tariffs_and_services.slider._9'), link: '/other-services' },
      ],
      image: slide_img_03,
    },
    {
      items: [
        { title: '10', text: t('tariffs_and_services.slider._10'), link: '/other-services' },
        { title: '11', text: t('tariffs_and_services.slider._11'), link: '/other-services' },
      ],
      image: slide_img_04,
    },
  ]

  return (
    <section className='section'>
      <div className='service-wrapper'>
        <div className='service-container container'>
          <div className='corporate-text-content '>
            <div className='corporate-title-block '>
              <div className='corporate-text-content'>
                <div className='service-title' dangerouslySetInnerHTML={{ __html: t('tariffs_and_services.title') }}></div>
              </div>
            </div>

            <div>
              <NewSlider slidesData={slidesData} />
            </div>
            <div className='table-title'>{t('tariffs_and_services.table.title')}</div>

            <div class='heading-container'>
              <h1 class='left-heading'>{t('tariffs_and_services.table.name')}</h1>
              <h1 class='right-heading'>{t('tariffs_and_services.table.amount')}</h1>
            </div>

            <div className='static-table'>
              {tableData.map((rowData, index) => (
                <div className='table-row' key={index}>
                  <div className='table-cell cellText' dangerouslySetInnerHTML={{ __html: rowData.leftContent }}></div>
                  <div className='table-cell cellText' dangerouslySetInnerHTML={{ __html: rowData.rightContent }}></div>
                </div>
              ))}
            </div>

            {/* Table End */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TariffsAndServices
