import React from 'react'
import s from './Contact.module.css'
import ContactForm from '../../components/contact-form/ContactForm'
import hero_img from '../../assets/contact/contact-hero.png'
import SplitCard from '../../components/SplitCard/SplitCard'
import { t } from 'i18next'

const Contact = () => {
  const heroData = {
    img: hero_img,
    title: t('contact.title'),
    description: t('contact.hero'),
    btnLabel: t('buttons.contact_us'),
    btnLink: '#contact-form',
    isHero: true,
  }
  return (
    <div className={s.contact_page}>
      {/* hero section */}
      <SplitCard {...heroData} />

      {/* contact form */}
      <div className={s.contact_us}>
        <ContactForm />
      </div>
    </div>
  )
}

export default Contact
