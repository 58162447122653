import React from 'react'
import './CorporateClients.css'
import heroImg from '../../assets/corporate-clients/hero-img.jpeg'
import profile_icon from '../../assets/corporate-clients/profile-icon.svg'
import group_icon from '../../assets/corporate-clients/group-icon.svg'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import SplitCard from '../../components/SplitCard/SplitCard'
import { t } from 'i18next'

function CorporateClients() {
  const docData = [
    { content: t('corporate_clients.line1') },
    { content: t('corporate_clients.line2') },
    { content: t('corporate_clients.line3') },
    { content: t('corporate_clients.line4') },
    { content: t('corporate_clients.line5') },
    { content: t('corporate_clients.line6') },
    { content: t('corporate_clients.line7') },
    { content: t('corporate_clients.line8') },
    { content: t('corporate_clients.line9') },
    { content: t('corporate_clients.line10') },
    { content: t('corporate_clients.line11') },
    { content: t('corporate_clients.line12') },
  ]

  const heroData = {
    img: heroImg,
    title: t('corporate_clients.title'),
    description: t('corporate_clients.hero'),
    btnLabel: t('buttons.open_account'),
    btnLink: '/register',
    isHero: true,
  }

  return (
    <section className='section'>
      {/* hero section */}
      <SplitCard {...heroData} />

      <div className='corporate-container container grid'>
        <div>
          <h2 className='corporate-subtitle'>{t('corporate_clients.acc_opening')}</h2>
        </div>

        <div class='opening-account'>
          <div class='opening-account-left'>
            <p>{t('corporate_clients.desc')}</p>
            <div class='btn-wrapper'>
              <RedBtn btnLabel={t('buttons.open_account')} btnLink='/register' white arrow />
            </div>
          </div>

          <div class='opening-account-right'>
            <div class='account-row'>
              <div class='account-row-content'>
                <div class='icon'>
                  <img src={profile_icon} alt='Feature 1' />
                </div>
                <div class='text'>
                  <h3>{t('corporate_clients.card_1_title')}</h3>
                  <p>{t('corporate_clients.card_1_text')}</p>
                </div>
              </div>
            </div>

            <div class='account-row'>
              <div class='account-row-content'>
                <div class='icon'>
                  <img src={group_icon} alt='Feature 2' />
                </div>
                <div class='text'>
                  <h3>{t('corporate_clients.card_2_title')}</h3>
                  <p>{t('corporate_clients.card_2_text')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='open-account-docs'>
          <h2 className='corporate-subtitle'>{t('corporate_clients.docs')}</h2>
        </div>
        <div className='doc-wraper'>
          <div className='document-title'>
            <h3>{t('corporate_clients.t_title')}</h3>
          </div>

          <div className='white-box'>
            {docData.map((_, index) => {
              return (
                <div key={index}>
                  <p>{_.content}</p>
                  {index < docData.length - 1 && <hr />}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CorporateClients
