import React from 'react'
import s from './PaymentCards.module.css'
import HeroWithBanner from '../../components/hero-with-banner/HeroWithBanner'
import heroBanner from '../../assets/payment-cards/hero-banner.webp'
import virtual_card from '../../assets/payment-cards/virtual-card.png'
import physical_card from '../../assets/payment-cards/physical-card.png'
import { t } from 'i18next'

function PaymentCards() {
  const heroData = {
    heroImage: heroBanner,
    title: t('payment_cards.title'),
    desc: t('payment_cards.hero'),
    btnLabel: t('buttons.order_card'),
    btnLink: '/contact',
    bgGradient: true,
    textLeft: true,
  }

  return (
    <div className={s.payment_cards_page}>
      {/* hero section */}
      <HeroWithBanner {...heroData} />

      {/* bank cards */}
      <div className={`${s.cards} container`}>
        {/* virtual card */}
        <div className={s.card}>
          <div className={s.card_info}>
            <div className={s.info}>
              <h3>{t('payment_cards.v_card.title')}</h3>
              <div className={s.features}>
                <p>{t('payment_cards.v_card.line1')}</p>
                <p>{t('payment_cards.v_card.line2')}</p>
                <p>{t('payment_cards.v_card.line3')}</p>
                <p>{t('payment_cards.v_card.line4')}</p>
              </div>
            </div>
            {/* card img for desktop */}
            <img src={virtual_card} alt='virtual card' className={s.card_img_desktop} />
          </div>
          <div className={s.about_card}>
            <div>
              <p>{t('payment_cards.v_card.payment_s')}</p>
              <p>{t('payment_cards.v_card.master_c')}</p>
            </div>
            <div>
              <p>{t('payment_cards.v_card.currency')}</p>
              <p>{t('payment_cards.v_card.usd')}</p>
            </div>
            <div>
              <p>{t('payment_cards.v_card.expiry_date')}</p>
              <p>{t('payment_cards.v_card._5y')}</p>
            </div>
            <div>
              <p>{t('payment_cards.v_card.service')}</p>
              <p>{t('payment_cards.v_card.free')}</p>
            </div>
          </div>
          {/* card img for mobile */}
          <img src={virtual_card} alt='virtual card' className={s.card_img_mobile} />
        </div>

        {/* physical card */}
        <div className={s.card}>
          <div className={s.card_info}>
            <div className={s.info}>
              <h3>{t('payment_cards.p_card.title')}</h3>
              <div className={s.features}>
                <p>{t('payment_cards.p_card.line1')}</p>
                <p>{t('payment_cards.p_card.line2')}</p>
                <p>{t('payment_cards.p_card.line3')}</p>
                <p>{t('payment_cards.p_card.line4')}</p>
              </div>
            </div>
            {/* card img for desktop */}
            <img src={physical_card} alt='physical card' className={s.card_img_desktop} />
          </div>
          <div className={s.about_card}>
            <div>
              <p>{t('payment_cards.p_card.payment_s')}</p>
              <p>{t('payment_cards.p_card.master_c')}</p>
            </div>
            <div>
              <p>{t('payment_cards.p_card.currency')}</p>
              <p>{t('payment_cards.p_card.usd')}</p>
            </div>
            <div>
              <p>{t('payment_cards.p_card.expiry_date')}</p>
              <p>{t('payment_cards.p_card._5y')}</p>
            </div>
            <div>
              <p>{t('payment_cards.p_card.service')}</p>
              <p>{t('payment_cards.p_card.free')}</p>
            </div>
          </div>
          {/* card img for mobile */}
          <img src={physical_card} alt='physical card' className={s.card_img_mobile} />
        </div>
      </div>
    </div>
  )
}

export default PaymentCards
