import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './ForgotPassword.css'
import { resetPassword } from '../../store/reducers/actions'
import { Formik } from 'formik'
import { useAppDispatch } from '../../store/store'
import NewCustomModal from '../../components/ui/newCustomModal/newCustomModal'
import MyInput from '../../components/ui/MyInput/MyInput'
import Logo from '../../assets/logo_with_text.svg'
import BackIcon from '../../assets/icons/arrow.svg'
import RedBtn from '../../components/ui/redBtn/RedBtn'

function ForgotPassword() {
  const dispatch = useAppDispatch()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupType, setpopupType] = useState('')

  return (
    <>
      <NewCustomModal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} popupType={popupType} />

      <div className='auth-wrapper'>
        <div className='auth-container container'>
          <Link to='/login' className='back_arrow_icon'>
            <img src={BackIcon} alt='Back' />
          </Link>

          <div className='sign_in_wrapper'>
            <div className='sign_in_content'>
              <img src={Logo} alt='Logo' className='auth-logo-img' />

              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={(values, actions) => {
                  dispatch(resetPassword(values))
                    .then(() => {
                      setIsPopupOpen(true)
                      setpopupType('confirm')
                    })
                    .catch(error => {
                      console.error('Reset error:', error)
                      setpopupType('error')
                      setIsPopupOpen(true)
                    })
                    .finally(() => {
                      actions.setSubmitting(false)
                    })
                }}>
                {formikProps => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div className='sign_in_content'>
                      <div className='title '>
                        Утратили доступ?
                        <div className='info'>
                          Введите свою электронную почту привязанную к вашему личному аккаунту СберКассы.
                        </div>
                      </div>

                      <MyInput
                        id='email'
                        name='email'
                        placeholder='Введите почту'
                        value={formikProps.values.email}
                        onChange={formikProps.handleChange}
                      />

                      <div className='btn_block'>
                        <RedBtn btnLabel='Продолжить' type='submit' />
                      </div>

                      <div className='auth-question'>
                        Нет аккаунта СберКассы?
                        <a href='/register' className='auth-question-link'>
                          Зарегистрироваться
                        </a>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
