import React, { useState } from 'react'
import { useAppDispatch } from '../../../store/store'
import { Formik } from 'formik'
import { finalLogin } from '../../../store/reducers/actions'
import MyInput from '../../../components/ui/MyInput/MyInput'
import { zeroFill } from '../../../helpers/zeroFill'
import NewCustomModal from '../../../components/ui/newCustomModal/newCustomModal'
import { useNavigate } from 'react-router-dom'
import RedBtn from '../../../components/ui/redBtn/RedBtn'

function SignInFinal(props) {
  const dispatch = useAppDispatch()
  const [time, setTime] = useState(120)
  const [timeIsOver, setTimeIsOver] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const tick = () => {
    if (time === 0) {
      setTimeIsOver(true)
      props.setCurrent(0)
      return
    }
    setTime(prevState => prevState - 1)
  }
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => clearInterval(timerID)
  })
  const navigate = useNavigate()

  return (
    <>
      <NewCustomModal
        message='Пожалуйста, проверьте правильность введенных данных и попробуйте снова.'
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        popupType={'error'}
      />

      <Formik
        initialValues={{
          email: props.dataForSend.email,
          password: props.dataForSend.password,
          emailCode: '',
        }}
        onSubmit={(values, actions) => {
          dispatch(finalLogin(values))
            .then(() => {
              console.log('Login successful!')
              navigate('/kabinet')
            })
            .catch(error => {
              console.error('Login error:', error)
              setIsPopupOpen(true)
            })
            .finally(() => {
              actions.setSubmitting(false)
            })
        }}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className='sign_in_content'>
              <div className='title'>Код был отправлен на вашу электронную почту</div>

              <MyInput
                id='emailCode'
                name='emailCode'
                placeholder='Код подтверждения'
                value={formikProps.values.emailCode}
                onChange={formikProps.handleChange}
              />

              <div className='btn_block'>
                <RedBtn btnLabel='Отправить' type='submit' />
              </div>

              <div className='dont_have_account'>
                {timeIsOver ? (
                  <div></div>
                ) : (
                  <div>
                    {' '}
                    {zeroFill(minutes, 2)} : {zeroFill(seconds, 2)}
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default SignInFinal
