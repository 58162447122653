import React, { useEffect, useState } from 'react'
import './AccountStatement.css'
import { useAppDispatch, useAppSelector } from '../../../../store/store'
import { getAccountsListThunk, GetTransactionsListThunk } from '../../../../store/reducers/actions'
import debounce from 'lodash.debounce'
import CustomDropdown from '../../CurrencyExchange/ExchangeStepOne/CustomDropdown'
import MyBtn from '../../MyBtn/MyBtn'
import Dropdown from '../../Dropdown/Dropdown'
import VerticalBar from '../../../../assets/currency/vertical-bar.png'
import { handleDownloadPDF } from '../../../../helpers/StatementDownloadPDF'
import { handleDownloadCSV } from '../../../../helpers/StatementDownloadCSV'

const AccountStatement = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(store => store.userReducer.user.userData)
  const accounts = useAppSelector(store => store.userReducer.accountsList) || []
  const transactions = useAppSelector(store => store.transactionsReducer.transactionsData) || []
  const { userToken } = useAppSelector(store => store.userReducer.user)

  const [selectedFromAccount, setSelectedFromAccount] = useState(null)
  const [filters, setFilters] = useState({ fromDate: '', toDate: '', recipient: '', type: '' })
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [showIncoming, setShowIncoming] = useState(false) // State for IN checkbox
  const [showOutgoing, setShowOutgoing] = useState(false) // State for OUT checkbox
  const [hoveredTransaction, setHoveredTransaction] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8

  useEffect(() => {
    if (userToken) {
      dispatch(getAccountsListThunk(userToken))
      dispatch(GetTransactionsListThunk(userToken))
    }
  }, [dispatch, userToken])

  useEffect(() => {
    applyFilters()
  }, [filters, transactions])

  const handleAccountChange = accountNumber => {
    const selectedAccount = accounts.find(acc => acc.number === accountNumber)
    setSelectedFromAccount(selectedAccount)
  }

  const handleFilterChange = (key, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [key]: value }))
  }

  const applyFilters = () => {
    let filtered = transactions

    if (selectedFromAccount) {
      filtered = filtered.filter(transaction => transaction.fromAccount === selectedFromAccount.number)
    }
    if (filters.period) {
      const today = new Date()
      let startDate, endDate

      switch (filters.period) {
        case 'Today':
          startDate = new Date(today.setHours(0, 0, 0, 0))
          endDate = new Date(today.setHours(23, 59, 59, 999))
          break
        case 'Yesterday':
          startDate = new Date(today.setDate(today.getDate() - 1))
          startDate.setHours(0, 0, 0, 0)
          endDate = new Date(today.setHours(23, 59, 59, 999))
          break
        case 'This week':
          startDate = new Date(today.setDate(today.getDate() - today.getDay()))
          startDate.setHours(0, 0, 0, 0)
          endDate = new Date()
          endDate.setHours(23, 59, 59, 999)
          break
        case 'This month':
          startDate = new Date(today.getFullYear(), today.getMonth(), 1)
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
          break
        case 'Last week':
          const lastWeek = new Date(today.setDate(today.getDate() - 7))
          startDate = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay()))
          startDate.setHours(0, 0, 0, 0)
          endDate = new Date(startDate)
          endDate.setDate(endDate.getDate() + 6)
          endDate.setHours(23, 59, 59, 999)
          break
        case 'Last 30 days':
          startDate = new Date(today.setDate(today.getDate() - 30))
          startDate.setHours(0, 0, 0, 0)
          endDate = new Date()
          endDate.setHours(23, 59, 59, 999)
          break
        case 'Last month':
          startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
          endDate = new Date(today.getFullYear(), today.getMonth(), 0)
          break
        case 'Last and current month':
          startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
          break
        default:
          break
      }

      if (startDate && endDate) {
        filtered = filtered.filter(
          transaction => new Date(transaction.created * 1000) >= startDate && new Date(transaction.created * 1000) <= endDate
        )
      }
    }
    if (filters.fromDate) {
      filtered = filtered.filter(transaction => new Date(transaction.created * 1000) >= new Date(filters.fromDate))
    }
    if (filters.toDate) {
      filtered = filtered.filter(transaction => new Date(transaction.created * 1000) <= new Date(filters.toDate))
    }
    if (filters.recipient) {
      filtered = filtered.filter(transaction => transaction.toAccount.toLowerCase().includes(filters.recipient.toLowerCase()))
    }
    if (filters.type) {
      const lowerCaseType = filters.type.toLowerCase()
      filtered = filtered.filter(transaction => {
        const translatedType = transactionTypeTranslations[transaction.transactionType] || transaction.transactionType
        return (
          transaction.transactionType.toLowerCase().includes(lowerCaseType) ||
          translatedType.toLowerCase().includes(lowerCaseType)
        )
      })
    }

    // Filter by direction (IN/OUT)
    if (showIncoming) {
      filtered = filtered.filter(transaction => transaction.direction === 'IN')
    }
    if (showOutgoing) {
      filtered = filtered.filter(transaction => transaction.direction === 'OUT')
    }

    setFilteredTransactions(filtered)
    setCurrentPage(1) // Reset to the first page after filtering
  }

  const debouncedTypeFilter = debounce(typeValue => {
    setFilters(prevFilters => ({ ...prevFilters, type: typeValue }))
  }, 300)

  const handleCheckboxChange = type => {
    if (type === 'IN') {
      setShowIncoming(prev => !prev)
    } else if (type === 'OUT') {
      setShowOutgoing(prev => !prev)
    }
  }

  const handleTypeChange = e => {
    const value = e.target.value
    setFilters(prevFilters => ({ ...prevFilters, type: value }))
  }

  const formatTimestamp = timestamp => {
    const date = new Date(timestamp * 1000)
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return date.toLocaleDateString('ru-RU', options)
  }

  const handlePageChange = direction => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1)
    } else if (direction === 'next' && currentPage < Math.ceil(filteredTransactions.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentTransactions = filteredTransactions.slice(startIndex, startIndex + itemsPerPage)

  const periodOptions = {
    Today: 'Сегодня',
    Yesterday: 'Вчера',
    'This week': 'На этой неделе',
    'This month': 'В этом месяце',
    'Last week': 'За последнюю неделю',
    'Last 30 days': 'Последние 30 дней',
    'Last month': 'Последний месяц',
    'Last and current month': 'Последний и текущий месяц',
  }

  const transactionTypeTranslations = {
    'Intra Transaction': 'Внутренняя транзакция',
    'Europe Transaction (SEPA)': 'Европейская транзакция (SEPA)',
    'International Transaction(SWIFT)': 'Международная транзакция (SWIFT)',
    'International Transaction (Pan Africa)': 'Международная транзакция (Пан Африка)',
    'Exchange Transaction': 'Транзакция обмена',
    'ACH Transaction': 'Транзакция ACH',
    'EFT Transaction': 'Транзакция EFT',
    'BACS Transaction': 'Транзакция BACS',
    'Domestic Wire Transaction': 'Местный банковский перевод',
    'Card Transaction': 'Транзакция по карте',
    'Crypto Transaction': 'Крипто-транзакция',
    'INTERAC Transaction': 'Транзакция INTERAC',
    'Bill Payment Transaction': 'Транзакция оплаты счета',
  }

  const transactionReferenceTranslations = {
    Internal: 'Внутренняя операция',
    Exchange: 'Обмен валюты',
    International: 'Международная операция',
  }

  const directionTranslations = {
    IN: 'Входящий',
    OUT: 'Исходящий',
  }

  const transactionStatusTranslations = {
    New: 'Новый',
    Approved: 'Одобрен',
    Denied: 'Отклонен',
    Processing: 'В обработке',
    Canceling: 'Отмена',
    Canceled: 'Отменен',
    Refunded: 'Возвращен',
    Error: 'Ошибка',
  }

  const getTranslatedTransactionType = type => transactionTypeTranslations[type] || type

  const getTranslatedTransactionReference = reference => transactionReferenceTranslations[reference] || reference

  const getTranslatedTransactionStatus = status => transactionStatusTranslations[status] || status

  const getTranslatedDirection = direction => directionTranslations[direction] || direction

  const getTranslatedTransactions = transactions => {
    return transactions.map(transaction => ({
      ...transaction,
      transactionType: getTranslatedTransactionType(transaction.transactionType),
      reference: getTranslatedTransactionReference(transaction.reference),
      direction: getTranslatedDirection(transaction.direction),
      transactionStatus: getTranslatedTransactionStatus(transaction.transactionStatus), // Add status translation
    }))
  }

  const handlePeriodChange = selectedOption => {
    handleFilterChange('period', selectedOption)
  }

  const handleMouseEnter = transaction => {
    setHoveredTransaction({ ...transaction })
  }

  const handleMouseLeave = () => {
    setHoveredTransaction(null)
  }

  return (
    <>
      <div className='account-statement-filter-container dashboard-dim-container'>
        <form
          className='currency-transaction-filter-form'
          onSubmit={e => {
            e.preventDefault()
            applyFilters()
          }}>
          <div className='currency-filter-form-box '>
            <label htmlFor='accountNumber' className='transfer-form-label'>
              Номер счета
            </label>
            <div className='currency-filter-dropdown'>
              <CustomDropdown
                options={accounts}
                selectedValue={selectedFromAccount?.number}
                onChange={e => handleAccountChange(e.target.value)}
                placeholder=''
              />
            </div>
          </div>

          <div className='currency-transaction-form-group'>
            <div className='currency-filter-form-box'>
              <label htmlFor='period' className='transfer-form-label'>
                Период
              </label>
              <Dropdown
                field={{
                  name: 'period',
                  value: filters.period ? periodOptions[filters.period] : '', // Show Russian label in dropdown
                }}
                form={{
                  setFieldValue: (_, value) => {
                    // Map Russian label back to English key when selected
                    const selectedKey = Object.keys(periodOptions).find(key => periodOptions[key] === value)
                    handlePeriodChange(selectedKey)
                  },
                }}
                options={Object.values(periodOptions)} // Pass only Russian labels
                placeholder=''
              />
            </div>
            <div className='currency-filter-form-box'>
              <label htmlFor='fromDate' className='transfer-form-label'>
                с
              </label>
              <input
                type='date'
                id='fromDate'
                value={filters.fromDate}
                onChange={e => handleFilterChange('fromDate', e.target.value)}
                className='currency-filter-input'
              />
            </div>
            <div className='currency-filter-form-box'>
              <label htmlFor='toDate' className='transfer-form-label'>
                до
              </label>
              <input
                type='date'
                id='toDate'
                value={filters.toDate}
                onChange={e => handleFilterChange('toDate', e.target.value)}
                className='currency-filter-input'
              />
            </div>
          </div>

          <div className='currency-transaction-form-group'>
            <div className='currency-filter-form-box'>
              <label htmlFor='recipient' className='transfer-form-label'>
                Получатель
              </label>
              <input
                type='text'
                id='recipient'
                placeholder=''
                value={filters.recipient}
                onChange={e => handleFilterChange('recipient', e.target.value)}
                className='currency-filter-input'
              />
            </div>
            <div className='currency-filter-form-box'>
              <label htmlFor='type' className='transfer-form-label'>
                Тип транзакции
              </label>
              <input
                type='text'
                id='type'
                placeholder=''
                value={filters.type}
                onChange={handleTypeChange}
                className='currency-filter-input'
              />
            </div>
          </div>

          <div className='currency-transaction-form-group'>
            <div className='currency-filter-form-box'>
              <label htmlFor='recipient' className='transfer-form-label'>
                транзакции
              </label>
              <div>
                <input
                  type='checkbox'
                  id='inTransactions'
                  checked={showIncoming}
                  onChange={() => handleCheckboxChange('IN')}
                  className='currency-transaction-form-checkbox'
                />
                <span>Входящие</span>
                &nbsp; &nbsp; &nbsp;
                <input
                  type='checkbox'
                  id='outTransactions'
                  checked={showOutgoing}
                  onChange={() => handleCheckboxChange('OUT')}
                  className='currency-transaction-form-checkbox'
                />
                <span>Исходящие</span>
              </div>
            </div>
          </div>

          <div className='currency-transaction-button'>
            <MyBtn type='submit' title='Показать' className='primary-button' />
          </div>
        </form>
      </div>

      <div className='currency-statement-download'>
        <span className='currency-download-label'>Загрузить</span>
        <span
          className='download-format'
          onClick={() => handleDownloadPDF(getTranslatedTransactions(filteredTransactions), userData)}>
          PDF
        </span>
        <img src={VerticalBar} alt='vertical bar' className='currency-transaction-bar' />
        <span className='download-format' onClick={() => handleDownloadCSV(getTranslatedTransactions(filteredTransactions))}>
          CSV
        </span>
      </div>

      <div className='dashboard-dim-container '>
        <div className='transaction-info-panel'>
          <div>
            <article className='transaction-info-panel_item'>
              <span className='transaction-info-panel_label'>Номер счета:</span>
              <span className='transaction-info-panel_value'>{selectedFromAccount ? selectedFromAccount.number : ''}</span>
            </article>
            <article className='transaction-info-panel_item'>
              <span className='transaction-info-panel_label'>Валюта:</span>
              <span className='transaction-info-panel_value'>{selectedFromAccount ? selectedFromAccount.currency : ''}</span>
            </article>
            <article className='transaction-info-panel_item'>
              <span className='transaction-info-panel_label'>Период:</span>
              <span className='transaction-info-panel_label'>
                с <span className='transaction-info-panel_value'>{filters.fromDate ? filters.fromDate : ''}</span>
              </span>
              <span className='transaction-info-panel_label'>
                до <span className='transaction-info-panel_value'>{filters.toDate ? filters.toDate : ''}</span>
              </span>
            </article>
          </div>

          <div className='transaction-info-panel_item'>
            <span className='transaction-info-panel_label'>Начальный баланс:</span>
            <span className='transaction-info-panel_value'>{selectedFromAccount ? selectedFromAccount.balance : ''}</span>
          </div>
        </div>

        <table className='currency-transaction-table'>
          <thead className='currency-transaction-header'>
            <tr className='header-row'>
              <th className='header-cell'>Дата</th>
              <th className='header-cell'>Перевод №</th>
              <th className='header-cell'>Отправитель</th>
              <th className='header-cell'>Получатель</th>
              <th className='header-cell'>Тип</th>
              <th className='header-cell'>Назначение</th>
              <th className='header-cell'>Сумма</th>
              <th className='header-cell'>Баланс</th>
            </tr>
          </thead>
          <tbody className='currency-transaction-body'>
            {currentTransactions.length > 0 ? (
              currentTransactions.map(transaction => (
                <tr
                  className='body-row'
                  key={transaction.transactionNumber}
                  onMouseEnter={() => handleMouseEnter(transaction)}
                  onMouseLeave={handleMouseLeave}>
                  <td className='body-cell' data-label='Дата'>
                    {formatTimestamp(transaction.created)}
                  </td>
                  <td className='body-cell' data-label='Перевод №'>
                    {transaction.transactionNumber}
                  </td>
                  <td className='body-cell' data-label='Отправитель'>
                    {transaction.fromAccount}
                  </td>
                  <td className='body-cell' data-label='Получатель'>
                    {transaction.toAccount}
                  </td>
                  <td className='body-cell' data-label='Тип'>
                    {getTranslatedTransactionType(transaction.transactionType)}
                  </td>
                  <td className='body-cell' data-label='Назначение'>
                    {getTranslatedTransactionReference(transaction.reference)}
                  </td>
                  <td
                    className='body-cell'
                    data-label='Сумма'
                    style={{
                      color: transaction.direction === 'IN' ? 'green' : transaction.direction === 'OUT' ? 'red' : 'inherit',
                    }}>
                    {transaction.direction === 'IN'
                      ? `+${transaction.amount}`
                      : transaction.direction === 'OUT'
                      ? `-${transaction.amount}`
                      : transaction.amount}{' '}
                    {transaction.currency}
                  </td>
                  <td className='body-cell' data-label='Балансnpm start'>
                    {transaction.balance} {transaction.direction}
                  </td>
                </tr>
              ))
            ) : (
              <tr className='body-row'>
                <td className='body-cell' colSpan='8'>
                  Нет данных
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Hover Details Section */}
        <div className='transaction-hover-container'>
          <div className={`transaction-hover-details ${hoveredTransaction ? 'visible' : 'hidden'}`}>
            {hoveredTransaction && (
              <>
                <article className='hover-detail'>
                  <span className='detail-label'>Кредитовый оборот:</span>
                  <span className='detail-value'>
                    {hoveredTransaction.balance} {hoveredTransaction.currency}
                  </span>
                </article>

                <article className='hover-detail'>
                  <span className='detail-label'>Дебетовый оборот:</span>
                  <span
                    className='detail-value'
                    style={{
                      color: hoveredTransaction.direction === 'IN' ? 'green' : 'red',
                    }}>
                    {hoveredTransaction.direction === 'IN' ? `+${hoveredTransaction.amount}` : `-${hoveredTransaction.amount}`}{' '}
                    {hoveredTransaction.currency}
                  </span>
                </article>

                <article className='hover-detail'>
                  <span className='detail-label'>Фиатный баланс:</span>
                  <span className='detail-value'>
                    {hoveredTransaction.balance} {hoveredTransaction.currency}
                  </span>
                </article>
              </>
            )}
          </div>
        </div>

        <div className='pagination'>
          <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
            Назад
          </button>
          <button
            onClick={() => handlePageChange('next')}
            disabled={currentPage === Math.ceil(filteredTransactions.length / itemsPerPage)}>
            Вперед
          </button>
        </div>
      </div>
    </>
  )
}

export default AccountStatement
