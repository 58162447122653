import React, { useState } from 'react'
import './SignIn.css'
import MyInput from '../../../components/ui/MyInput/MyInput'
import { getLoginList } from '../../../store/reducers/actions'
import { Formik } from 'formik'
import { useAppDispatch } from '../../../store/store'
import NewCustomModal from '../../../components/ui/newCustomModal/newCustomModal'
import RedBtn from '../../../components/ui/redBtn/RedBtn'
import { t } from 'i18next'

function SignIn(props) {
  const dispatch = useAppDispatch()
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  return (
    <>
      <NewCustomModal
        message='Пожалуйста, проверьте правильность введенных данных и попробуйте снова.'
        popupType='error'
        setIsPopupOpen={setIsPopupOpen}
        isPopupOpen={isPopupOpen}
      />

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, actions) => {
          props.setLoginData({ ...props.dataForSend, email: values.email, password: values.password })
          dispatch(getLoginList(values))
            .then(() => {
              console.log('Request successful!')
              props.setCurrent(1)
            })
            .catch(error => {
              console.error('Login error:', error)
              setIsPopupOpen(true)
            })
            .finally(() => {
              actions.setSubmitting(false)
            })
        }}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className='title'>{t('login.we_are_glad')}</div>
            <MyInput
              id='email'
              name='email'
              placeholder='E-mail'
              value={formikProps.values.email}
              onChange={formikProps.handleChange}
            />

            <MyInput
              id='password'
              name='password'
              password
              placeholder={t('login.password')}
              value={formikProps.values.password}
              onChange={formikProps.handleChange}
            />

            <a href='/forgot_password' className='span_password'>
            {t('login.forgot_password')}
            </a>

            <div className='btn_block'>
              <RedBtn btnLabel={t('buttons.login')} type='submit' disabled={!formikProps.isValid} />
            </div>

            <div className='auth-question'>
            {t('login.dont_have_account')}
              <a href='/register' className='auth-question-link'>
              {t('login.signup')}
              </a>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default SignIn
