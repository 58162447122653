import React, { useState } from 'react'
import s from './RedBtn.module.css'

const ArrowIcon = ({ isWhite }) => (
  <svg
    className={s.arrow}
    width='42'
    height='42'
    viewBox='0 0 43 43'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transition: 'stroke 0.3s ease, fill 0.3s ease' }}>
    <circle
      cx='21.5'
      cy='21.5'
      r='21.1019'
      stroke={isWhite ? '#ffffff' : '#D90429'}
      strokeWidth='0.796296'
      style={{ transition: 'stroke 0.3s ease' }}
    />
    <path
      d='M15.1299 20.7037C14.6901 20.7037 14.3336 21.0602 14.3336 21.5C14.3336 21.9398 14.6901 22.2963 15.1299 22.2963L15.1299 20.7037ZM28.4337 22.0631C28.7447 21.7521 28.7447 21.2479 28.4337 20.9369L23.3661 15.8693C23.0551 15.5584 22.5509 15.5584 22.24 15.8693C21.929 16.1803 21.929 16.6845 22.24 16.9955L26.7445 21.5L22.24 26.0045C21.929 26.3155 21.929 26.8197 22.24 27.1307C22.5509 27.4416 23.0551 27.4416 23.3661 27.1307L28.4337 22.0631ZM15.1299 22.2963L27.8706 22.2963L27.8706 20.7037L15.1299 20.7037L15.1299 22.2963Z'
      fill={isWhite ? '#ffffff' : '#D90429'}
      style={{ transition: 'fill 0.3s ease' }}
    />
  </svg>
)

const RedBtn = ({
  btnLabel,
  btnLink,
  arrow = false,
  fullWidth = false,
  type = 'button',
  white = false,
  onClick,
  disabled,
  ...rest
}) => {
  const [whiteArrow, setWhiteArrow] = useState(white)

  const isLink = !!btnLink

  return isLink ? (
    <a
      {...rest}
      href={btnLink}
      className={`${s.button} ${arrow ? s.arrow_padding : ''} ${fullWidth ? s.full_width : ''} ${white ? s.white : ''}`}
      onMouseEnter={() => setWhiteArrow(!white)}
      onMouseLeave={() => setWhiteArrow(white)}
      onClick={onClick}>
      <span>{btnLabel}</span>
      {arrow && <ArrowIcon isWhite={whiteArrow} />}
    </a>
  ) : (
    <button
      {...rest}
      disabled={disabled}
      type={type}
      className={`${s.button} ${fullWidth ? s.full_width : ''}  ${arrow ? s.arrow_padding : ''}`}
      onMouseEnter={() => setWhiteArrow(!white)}
      onMouseLeave={() => setWhiteArrow(white)}
      onClick={onClick}>
      <span>{btnLabel}</span>
      {arrow && <ArrowIcon isWhite={whiteArrow} />}
    </button>
  )
}

export default RedBtn
